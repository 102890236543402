import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./index.module.scss";

import AsSeenOnCard from "./asSeenOn.jsx";

import list from "./list.jsx";

import Slider from "react-slick";

const slickSetting = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  swipeToSlide: true,
  arrows: true,
  // autoplay: true,
  variableWidth: true,

  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        // slidesToScroll: 3,
        swipeToSlide: true,
        infinite: true,
        // arrows: false,
        // autoplay: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        // slidesToScroll: 2,
        swipeToSlide: true,
        infinite: true,
        // arrows: false,
        // autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        // slidesToScroll: 1,
        infinite: true,
        arrows: false,
        // autoplay: true,
        swipeToSlide: true,
      },
    },
  ],
};

const AsSeenOn = () => {
  return (
    <div
      style={{ backgroundColor: "rgb(14,14,14)", overflow: "hidden" }}
      id="as_seen_on"
    >
      <div className={styles.container}>
        <div className={styles.upper}>
          <div className={styles.headline}>
            <div className={styles.content}>
              As <span className={styles.contentBold}>seen</span> on
            </div>
          </div>
        </div>
        {/* <div style={{ position: "relative" }}>
          <div className={styles.arrow}>Back</div>
          
        </div> */}
        <div style={{ padding: "0px 32px" }}>
          <Slider
            {...slickSetting}
            // style={{ marginRight: "-1000px" }}
          >
            {list.map((item, key) => {
              return (
                <AsSeenOnCard
                  key={`as-seen-on-item-${key}`}
                  {...item}
                  className={styles["slider-item"]}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AsSeenOn;
