import image1 from "assets/img/roadmap/1.png";
import image2 from "assets/img/roadmap/2.png";
import image3 from "assets/img/roadmap/3.png";
import image4 from "assets/img/roadmap/4.png";
import styles from "./index.module.scss";

const roadmaps = [
  {
    image: image1,
    time: "Sep 2021",
    title: "MadTroopers Website Launch",
    description: "MadTrooper NFTs showcased and rarities revealed",
    className: styles["roadmap-item-0"],
    key: 0,
  },
  {
    image: image3,
    time: "Sep 2021",
    title: "MadTroopers Launch",
    description: (
      <div>
        The MadTrooper Army assembles! 3489 MadTrooper NFTs minted and 
        "verifeyed" on{" "}
        <a
          target="_blank"
          href="https://solanart.io/collections/madtrooper"
          className={styles.linkText}
        >
          Solanart
        </a>
        {" "}
        <a
          target="_blank"
          href="https://magiceden.io/marketplace/mad_trooper"
          className={styles.linkText}
        >
          Magic Eden
        </a>
        {" "}
        <a
          target="_blank"
          href="https://solsea.io/c/614e29f0ac0362e2c76ab55e"
          className={styles.linkText}
        >
          SolSea
        </a>
        {" "}and {" "}
        <a
          target="_blank"
          href="https://www.fractal.is/madtrooper/play-now"
          className={styles.linkText}
        >
          Fractal
        </a>
      </div>
    ),
    className: styles["roadmap-item-1"],
    key: 1,
  },
  {
    image: image4,
    time: "Sep 2021",
    title: "MadTrooper Soundtrack",
    description: "MadTrooper Official Soundtrack",
    className: styles["roadmap-item-2"],
    key: 2,
    url: "/#soundtrack",
  },
  {
    image: image2,
    time: "Oct 2021",
    title: "MadTroopers Airdrop",
    description: (
      <div>
        MadTrooper NFTs will be airdropped to random, lucky holders of{" "}
        <a
          target="_blank"
          href="https://www.degenape.academy"
          className={styles.linkText}
        >
          DegenApes Academy NFTs
        </a>
      </div>
    ),
    className: styles["roadmap-item-3"],
    key: 3,
  },
  {
    image: image1,
    time: "Dec 2021",
    title: "MadTroopers Merchandise",
    url: "https://shop.eddaverse.com",
    description: "Exclusive MadTroopers Merchandise",
    className: styles["roadmap-item-4"],
    key: 4,
  },
  {
    image: image3,
    time: "Dec 2021",
    title: "360-VR NFTs",
    url: "https://www.youtube.com/c/MadTrooper/videos",
    description: "Ultra-rare, 360-VR MadTrooper NFTs",
    className: styles["roadmap-item-5"],
    key: 5,
  },
  {
    image: image4,
    time: "Christmas 2021",
    title: "MadTrooper Staking",
    url: "https://staking.madtrooper.com",
    description: "First of its kind, Solana NFT Staking Platform",
    className: styles["roadmap-item-6"],
    key: 6,
  },
  //{
  //   image: image1,
  //   time: "Q1 2022",
  //   title: "MadTrooper Game",
  //   url: "https://game.madtrooper.com",
  //   description: "MadTrooper Blockchain Game - Desktop Version",
  //   className: styles["roadmap-item-7"],
  //   key: 7,
  // },
  // {
  //   image: image2,
  //   time: "Q2 2024",
  //   title: "MadTrooper Game",
  //   url: "https://game.madtrooper.com",
  //   description: "Mobile Version",
  //   className: styles["roadmap-item-8"],
  //   key: 8,
  // },
  {
    image: image2,
    time: <span style={{ fontSize: "32px" }}>&#8734;</span>,
    title: "EDDAVerse ecosystem",
    // url: "https://eddaverse.com",
    description: (
      <p>
        The MadTrooper IP continues to develop within the EDDAVerse ecosystem along with other characters featuring in the{" "}
        <a
          href="https://youtu.be/7Wjcs4pxRys?si=mPNewGy7_z6jLT-k"
          target="_blank"
          rel="noopener noreferrer"
        >
          AAA Blockchain Game
        </a>{" "}
         and{" "}
         <a
          href="https://youtu.be/Wp4m16ZY4b4?si=pYpiGFeC6sbtTVbo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Web3 Hollywood Blockbuster
        </a>
      </p>
    ),
    className: styles["roadmap-item-8"],
    key: 9,
  },
];

export default roadmaps;
