import axios from "axios";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}


const submitSubcription = (email) => {
  return axios.post(process.env.REACT_APP_SUBSCRIBE_API_ENDPOINT, {
    email,
  });
};

const MOBILE_WIDTH = 1024;

export { getWindowDimensions, MOBILE_WIDTH, submitSubcription };
