import React from "react";
import isNumber from "lodash/isNumber";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Back from "@material-ui/icons/NavigateBefore";
import Next from "@material-ui/icons/NavigateNext";
import classnames from "classnames";

const NavButton = withStyles({
  root: {
    color: "white",
  },
})(IconButton);

import styles from "./index.module.scss";

import IntroVideoList from "./introVideo";

const Controller = (props) => {
  const { currentIndex, nextIndex, slider } = props;

  console.log("cur", currentIndex);
  console.log("next", nextIndex);

  const onNext = () => {
    console.log("next", nextIndex);
    if (nextIndex < IntroVideoList.length && slider) {
      slider.goNext();
    }
  };

  const onPrev = () => {
    console.log("prev", currentIndex);
    if (currentIndex >= 0 && slider) {
      slider.goBack();
    }
  };

  const onChangeIndex = (key) => {
    if (slider) {
      slider.navigate({ currentTarget: { dataset: { key } } });
    }
  };

  return (
    <div className={styles.controller}>
      {/* <NavButton onClick={onPrev}>
        <Back />
      </NavButton>
      <Button onClick={onPrev}>
        <p className={styles.navItem}>{IntroVideoList[currentIndex].navText}</p>
      </Button>
      <hr className={styles.line} />
      <Button onClick={onNext}>
        <p className={styles.navItem} style={{ fontWeight: "bold" }}>
          {IntroVideoList[nextIndex].navText}
        </p>
      </Button>
      <NavButton onClick={onNext}>
        <Next />
      </NavButton> */}
      {IntroVideoList.map((intro, index) => (
        <Button onClick={() => onChangeIndex(index)}>
          <p
            className={classnames({
              [styles.navItem]: true,
              [styles.active]: currentIndex === index,
            })}
          >
            {intro.navText}
          </p>
        </Button>
      ))}
    </div>
  );
};

export default Controller;
