import image1 from "assets/img/themes/1.webp";
import image2 from "assets/img/themes/2.webp";
import image3 from "assets/img/themes/3.webp";
import image4 from "assets/img/themes/4.webp";
import image5 from "assets/img/themes/5.webp";



export const nfts = [
  { id: 1, image: image1, description: "Theme: Rock" },
  { id: 2, image: image2, description: "Theme: Cyberpunk" },
  { id: 3, image: image3, description: "Theme: Hippie" },
  { id: 4, image: image4, description: "Theme: Cyberpunk" },
  { id: 5, image: image5, description: "Theme: Retro" },
];

