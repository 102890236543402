import React, { useState, useRef, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import { withStyles } from "@material-ui/core/styles";

import styles from "./index.module.scss";
import IntroVideoList from "./introVideo";
import Controller from "./controller";
import Button from "components/CustomButtons/Button.js";
import discordImg from "assets/img/discord.png";
import { isMobile } from "react-device-detect";
import IconButton from "@material-ui/core/IconButton";
import Back from "@material-ui/icons/NavigateBefore";
import Next from "@material-ui/icons/NavigateNext";

const NavButton = withStyles({
  root: {
    color: "white",
  },
})(IconButton);

import "react-slideshow-image/dist/styles.css";

import { MOBILE_WIDTH, getWindowDimensions } from "../../utils";

import "./index.scss";

const customStyle = {
  slideContainer: {
    width: "100vw",
    height: "100vh",
    position: "relative",
    minHeight: "650px",
  },
  youtube: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    borderRadius: "8px",
    width: "220px",
    textDecoration: "none",
    margin: "10px 20px 10px 0px",
    display: "inline-flex",
    backgroundColor: "#a93bc0",
    color: "#fff",
    "&:hover,&:focus": {
      color: "inherit",
      opacity: 0.7,
    },
  },
  staking: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    borderRadius: "8px",
    width: "310px",
    textDecoration: "none",
    margin: "10px 20px 10px 0px",
    display: "inline-flex",
    backgroundColor: "#a93bc0",
    color: "#fff",
    "&:hover,&:focus": {
      color: "inherit",
      opacity: 0.7,
    },
  },
  discord: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    borderRadius: "8px",
    width: "180px",
    textDecoration: "none",
    margin: "10px 20px 10px 0px",
    display: "inline-flex",
    backgroundColor: "#a93bc0",
    color: "#fff",
    "&:hover,&:focus": {
      color: "inherit",
      opacity: 0.7,
    },
  },
};

const IntroVideo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const sliderRef = useRef(null);

  let _faceStyle = {
    width: "100vw",
    minHeight: "650px",
    height: "100vh",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  // useEffect(() => {
  //   setIndex(1);
  // }, []);
  return (
    <div
      className={styles.container}
      style={{ position: "relative" }}
      id="intro"
    >
      <div style={customStyle.slideContainer}>
        {/* <div className={styles.controllerContainer}>
          <Controller
            currentIndex={currentIndex}
            nextIndex={nextIndex}
            slider={sliderRef && sliderRef.current}
          />
        </div> */}
        <Fade
          arrows={false}
          duration={4000}
          onChange={(newIndex, oldIndex) => {
            console.log(newIndex, oldIndex);
            setCurrentIndex(newIndex);
            setNextIndex(oldIndex);
          }}
          ref={sliderRef}
          indicators={(idx) => (
            <Button className={styles.navButton}>
              {idx === 0 && (
                <NavButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (sliderRef.current) {
                      sliderRef.current.goBack();
                    }
                  }}
                >
                  <Back />
                </NavButton>
              )}
              {IntroVideoList[idx].navText}
              {idx === IntroVideoList.length - 1 && (
                <NavButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (sliderRef.current) {
                      sliderRef.current.goNext();
                    }
                  }}
                >
                  <Next />
                </NavButton>
              )}
            </Button>
          )}
        >
          {IntroVideoList.map((slideImage, index) => {
            return (
              <div
                key={index}
                style={{
                  background: `url("${slideImage.image}") no-repeat`,
                  cursor: "pointer",
                  ..._faceStyle,
                  ...slideImage.customStyle,
                }}
                onClick={() => window.open(slideImage?.url, "_blank")}
              >
                <div
                  className={styles.contentContainer}
                  style={slideImage.contentContainerStyle}
                >
                  <p className={styles.title} style={slideImage.titleStyle}>
                    {slideImage.title}{" "}
                  </p>
                  <p
                    className={styles.subTitle}
                    style={slideImage.subTitleStyle}
                  >
                    {slideImage.subTitle}{" "}
                  </p>
                  <p className={styles.desc}>{slideImage.desc}</p>
                  {
                  // !isMobile && (
                  //   <div
                  //     // className={styles.socialText}
                  //     onClick={(e) => {
                  //       e.stopPropagation();
                  //       window.open("https://game.madtrooper.com", "_blank");
                  //     }}
                  //     style={customStyle.discord}
                  //   >
                  //     {/* <Button className={styles.discordButton} solid> */}
                  //     {/* <i className={styles.socials + " fab fa-gamepad"} /> */}
                  //     <p className={styles.youtubeText}>Play the game</p>
                  //     {/* </Button> */}
                  //   </div>
                  // )
                  }
                  {/* <a
                    // className={styles.socialText}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://discord.com/invite/xhamXaYxfh"
                    style={customStyle.discord}
                  >
                    <i className={styles.socials + " fab fa-discord"} />
                    <p className={styles.youtubeText}>Discord</p>
                  </a>
                  <a
                    style={customStyle.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={slideImage.url}
                  >
                    <i className={styles.socials + " fab fa-youtube"} />
                    <p className={styles.youtubeText}>WATCH NOW</p>
                  </a> */}
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open("https://staking.madtrooper.com", "_blank");
                    }}
                    style={customStyle.staking}
                  >
                    <p className={styles.youtubeText}>Stake your MadTrooper</p>
                  </div>
                </div>
                {/* <div
                  className={styles.contentContainer}
                  style={{ bottom: "25%" }}
                >
                  <a
                    href="https://twitter.com/TheMadTrooper"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["social-btn"]}
                  >
                    <i className={styles.socials + " fab fa-x-twitter"} />{" "}
                    {!isMobile && "TWITTER"}
                  </a>
                  <a
                    href="https://www.youtube.com/c/MadTrooper/videos"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["social-btn"]}
                  >
                    <i className={styles.socials + " fab fa-youtube"} />{" "}
                    {!isMobile && "YOUTUBE"}
                  </a>
                  <a
                    className={styles["social-btn"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/themadtrooper/"
                  >
                    <i className={styles.socials + " fab fa-instagram"} />{" "}
                    {!isMobile && "INSTAGRAM"}
                  </a>
                </div> */}
              </div>
            );
          })}
        </Fade>
      </div>
    </div>
  );
};

export default IntroVideo;
