import React from "react";

import "./index.scss";
import IntroVideoList from "./introVideo";

import "react-slideshow-image/dist/styles.css";

import Slider from "react-slick";
import Item from "./Item";
const settings = {
  dots: true,
  infinite: true,
  speed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  pauseOnFocus: true,
};
const IntroVideo = () => {
  return (
    <div style={{}} id="mobile-intro">
      <Slider
        {...settings}
        // appendDots={(dots) => {
        //   console.log("dots", dots);
        //   return <div></div>;
        // }}
      >
        {IntroVideoList.map((video) => (
          <Item {...video} key={video.image} />
        ))}
      </Slider>
    </div>
  );
};
export default IntroVideo;
