const introVideo = [
  {
    title: "Surfing through NEOM",
    subTitle: "",
    desc: "MadTrooper and ABDRA3492 surf through The Line, NEOM",
    navText: "Surfing through NEOM",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/mt1.webp",
    url: "https://www.youtube.com/watch?v=1cY-vRGus4c",
  },
  {
    title: "MadTrooper Rising",
    subTitle: "",
    desc: "Rise one, rise all!",
    navText: "MadTrooper Rising",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/madtrooper_rising.webp",
    url: "https://www.youtube.com/watch?v=rSBGgdMJI6M&feature=youtu.be",
  },
  {
    title: "Queen's Table",
    subTitle: "",
    desc: "MadTrooper reports to the Queen's table",
    navText: "Queen's Table",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/queen_table.webp",
    url: "https://youtu.be/XqleFc13Y9o",
  },
  {
    title: "War. to end all war",
    subTitle: "",
    desc: "Taking decisions that others can't to leave a legacy that others won't.",
    navText: "War. to end all war",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/to_end_all_war.webp",
    url: "https://youtu.be/ESsaByCAI_0",
  },
  {
    title: "MadTrooper Party",
    subTitle: "",
    desc: "MadTrooper breaks into the otherside and parties with BAYC and Crypto Punk!",
    navText: "MadTrooper Party",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/party.webp",
    url: "https://youtu.be/235ODyTsF60",
  },
  {
    title: "Raze",
    subTitle: "",
    desc: "MadTrooper on Planet Tzukanda",
    navText: "Raze",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/mt2.webp",
    url: "https://youtu.be/sviNh751Q-c",
  },
  {
    title: "Energy Cube",
    subTitle: "",
    desc: "MadTrooper retrieves the Energy Cube",
    navText: "Energy Cube",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/cube.webp",
    url: "https://youtu.be/4ufBQJ6LphA",
  },
];

export default introVideo;
