import React from "react";
import styles from "./item.module.scss";

const Item = (item) => {
  console.log("item", item.image);
  return (
    <div>
      <div
        className={styles["item-container"]}
        style={{ backgroundImage: `url(${item.image})`, ...item.customBgStyle }}
        onClick={() => window.open(item?.url, "_blank")}
      >
        <div className={styles["navigation"]}>
          <div
            className={styles["circle"]}
            onClick={() =>
              window.open("https://staking.madtrooper.com", "_blank")
            }
          >
            Staking
          </div>
          {/* <div
            className={styles["circle"]}
            onClick={() => window.open("https://game.madtrooper.com", "_blank")}
          >
            Game
          </div> */}
        </div>
      </div>
      <div className={styles["content"]}>
        <p style={{ fontSize: "32px", fontWeight: "bold" }}>{item.title}</p>
        <p style={{ fontSize: "24px", fontWeight: "500" }}>{item.desc}</p>
      </div>
    </div>
  );
};

export default Item;
