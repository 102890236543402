import img3 from "assets/img/Markets/3.jpeg";
import img11 from "assets/img/Markets/11.png";
import solsea from "assets/img/Markets/solsea.jpg";
import magiceden from "assets/img/Markets/magiceden.jpg";
import solanart from "assets/img/Markets/solanart.jpeg";
import fractal from "assets/img/Markets/fractal.jpeg";


const teams = [
  // {
  //   id: 3,
  //   image: img3,
  //   link: "https://digitaleyes.market/collections/MadTrooper",
  //   desc: "DigitalEyes Market",
  // },
  // {
  //   id: 11,
  //   image: img11,
  //   link: "https://solshop.io/madtrooper",
  //   desc: "SolShop",
  // },
  {
    id: 12,
    image: solsea,
    link: "https://solsea.io/collection/614e29f0ac0362e2c76ab55e",
    desc: "SolSea",
  },
  {
    id: 13,
    image: magiceden,
    link: "https://magiceden.io/marketplace/mad_trooper",
    desc: "Magic Eden",
  },
  {
    id: 5,
    image: solanart,
    link: "https://solanart.io/collections/madtrooper",
    desc: "Solanart",
  },
  {
    id: 5,
    image: fractal,
    link: "https://www.fractal.is/madtrooper",
    desc: "Fractal",
  },

];

export default teams;
