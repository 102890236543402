import React from "react";

import { BANNERS } from "./img";

import Banner from "./Banner";

import styles from "./index.module.scss";

const DownloadBanner = () => {
  return (
    <div className={styles.container}>
      {BANNERS.map((banner) => (
        <Banner {...banner} />
      ))}
    </div>
  );
};

export default DownloadBanner;
