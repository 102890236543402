import React from "react";

import styles from "./index.module.scss";

const Item = (props) => {
  const { link, image, desc, width, height } = props;
  return (
    <div {...props}>
      <div className={styles["img-container"]}>
        <a href={link} target="_blank">
          <img src={image} width={width || 180} height={height || 50} />
        </a>
      </div>
      <div
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          lineHeight: "150%",
          color: "#fff",
        }}
      >
        {desc}
      </div>
    </div>
  );
};

export default Item;
