import React from "react";

import styles from "./Banner.module.scss";
import DownloadIcon from "assets/img/download.png";

const Banner = ({ url, fullUrl, title }) => {
  return (
    <div className={styles.banner}>
      <div className={styles["banner-title"]}>{title}</div>
      <img src={url} alt="" className={styles["banner-img"]} />
      <a
        className={styles.download}
        target="_blank"
        rel="noreferrer noopener"
        href={fullUrl}
      >
        <img src={DownloadIcon} style={{ marginRight: "16px" }} />
        Download
      </a>
    </div>
  );
};

export default Banner;
