import React, { useEffect, useState } from "react";
import { useForm } from "../forms/useForm";
import classnames from "classnames";
import styles from "./mobile.module.scss";
import { submitSubcription } from '../../utils';

const ContactForm = () => {
  const [validateError, setValidateError] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const {
    inputValues,
    handleChange,
    errors,
    validateForm,
    validateField,
    resetState,
  } = useForm("contacts", [
    {
      name: "EMAIL",
      required: true,
      isPhoneOrEmail: true,
    },
  ]);

  useEffect(() => {
    if (errors && errors.EMAIL) {
      setValidateError(true);
    } else {
      setValidateError(false);
    }
  }, [errors]);

  const submitForm = async () => {
    let isValidForm = validateForm();
    
    if (isValidForm) {
      try {
        
        const email = inputValues.EMAIL;
        setStatus("sending");
        const response = await submitSubcription(email);
        setStatus("success");
        console.log("response submitSubcription", response);
        resetState();
      } catch (e) {
        setStatus("error");
        setMessage("error submit");
        console.log("exception submitForm", e);
        resetState();
      }
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  return (
    <div>
      <div className={styles.form}>
        <input
          id="email"
          type="email"
          name="EMAIL"
          placeholder="Enter email address"
          className={styles.email}
          onChange={handleChange}
          onBlur={validateField}
          onKeyDown={(e) => _handleKeyDown(e)}
        />
        <button
          className={classnames({
            [styles["email-btn"]]: true,
          })}
          onClick={submitForm}
        >
          Become a Madtrooper
        </button>
      </div>
      <div style={{ height: "50px" }}>
        {status === "sending" && (
          <div style={{ color: "white" }}>
            <p>Sending...</p>
          </div>
        )}
        {status === "error" && (
          <p
            style={{ color: "white" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && !validateError && (
          <div style={{ color: "white" }}>
            <p>Subscribed !</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
