const Mapping = [['#1','1','Legendary Master','Rock','Plum','Gold Mohawk','Purple Chain','Blue Galactic Gun','Gold Laser Spear'],
['#2','1723','Legendary Master','Retro','Lime Gradient','Gold Headphones','White Walkman','Yellow Galactic Gun','Gold Laser Spear'],
['#3','2676','Legendary Master','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Gold Galactic Gun','Gold Laser Spear'],
['#4','707','Legendary Master','Rock','Plum','Red Mohawk','Red Chain','Blue Galactic Gun','Gold Laser Spear'],
['#5','5','Legendary Master','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Green Galactic Gun','Gold Laser Spear'],
['#6','3308','Legendary Master','Retro','Lime Gradient','Green Headphones','Orange Walkman','Yellow Galactic Gun','Gold Laser Spear'],
['#7','3242','Legendary Master','Cyberpunk','Marigold','Green Mask','Pink Earphones','Green Galactic Gun','Gold Laser Spear'],
['#8','1876','Legendary Master','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Green Galactic Gun','Gold Laser Spear'],
['#9','4','Legendary Master','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Yellow Galactic Gun','Gold Laser Spear'],
['#10','2980','Legendary Master','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Gold Galactic Gun','Purple Laser Spear'],
['#11','0','Legendary Master','Rock','Plum','Gold Mohawk','Purple Chain','Gold Galactic Gun','Purple Laser Spear'],
['#12','2064','Legendary Master','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Yellow Galactic Gun','Purple Laser Spear'],
['#13','3219','Legendary Master','Retro','Mango Gradient','Gold Headphones','White Walkman','Blue Galactic Gun','Purple Laser Spear'],
['#14','7','Legendary Master','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Yellow Galactic Gun','Purple Laser Spear'],
['#15','9','Legendary Master','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Gold Galactic Gun','Purple Laser Spear'],
['#16','2742','Legendary Master','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Yellow Galactic Gun','Purple Laser Spear'],
['#17','692','Legendary Master','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Blue Galactic Gun','Purple Laser Spear'],
['#18','1766','Legendary Master','Retro','Mango Gradient','Green Headphones','Purple Walkman','Yellow Galactic Gun','Purple Laser Spear'],
['#19','1342','Legendary Master','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Green Galactic Gun','Purple Laser Spear'],
['#20','2243','Legendary Master','Rock','Inky','Gold Mohawk','Red Chain','Yellow Galactic Gun','Green Laser Spear'],
['#21','2309','Legendary Master','Rock','Inky','Red Mohawk','Red Chain','Gold Galactic Gun','Green Laser Spear'],
['#22','2802','Legendary Master','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Yellow Galactic Gun','Green Laser Spear'],
['#23','739','Legendary Master','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Green Galactic Gun','Green Laser Spear'],
['#24','6','Legendary Master','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Gold Galactic Gun','Red Laser Spear'],
['#25','1902','Legendary Master','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Gold Galactic Gun','Green Laser Spear'],
['#26','950','Legendary Master','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Galactic Gun','Green Laser Spear'],
['#27','2389','Legendary Master','Rock','Plum','Red Mohawk','Gold Chain','Gold Galactic Gun','Red Laser Spear'],
['#28','1918','Legendary Master','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Yellow Galactic Gun','Green Laser Spear'],
['#29','2718','Legendary Master','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Yellow Galactic Gun','Green Laser Spear'],
['#30','2306','Legendary Master','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Blue Galactic Gun','Green Laser Spear'],
['#31','1957','Legendary Master','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Green Galactic Gun','Green Laser Spear'],
['#32','3171','Legendary Master','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Green Galactic Gun','Green Laser Spear'],
['#33','2','Legendary Master','Rock','Plum','Green Mohawk','Gold Chain','Blue Galactic Gun','Red Laser Spear'],
['#34','3014','Legendary Master','Retro','Lavender Gradient','White Headphones','Gold Walkman','Green Galactic Gun','Red Laser Spear'],
['#35','1394','Legendary Master','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Gold Galactic Gun','Red Laser Spear'],
['#36','1823','Legendary Master','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Green Galactic Gun','Red Laser Spear'],
['#37','2933','Legendary Master','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Green Galactic Gun','Red Laser Spear'],
['#38','8','Legendary Master','Retro','Mango Gradient','White Headphones','White Walkman','Green Galactic Gun','Red Laser Spear'],
['#39','1528','Legendary Master','Retro','Mango Gradient','White Headphones','White Walkman','Yellow Galactic Gun','Red Laser Spear'],
['#40','1468','Legendary Master','Rock','Charcoal','Purple Mohawk','White Chain','Blue Galactic Gun','Red Laser Spear'],
['#41','2928','Legendary Master','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Blue Galactic Gun','Red Laser Spear'],
['#42','3','Legendary Master','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Blue Galactic Gun','Red Laser Spear'],
['#43','502','Commander','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Gold Galactic Gun','Gold Disc of Death'],
['#44','2012','Commander','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Gold Galactic Gun','Gold Disc of Death'],
['#45','3349','Commander','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Blue Galactic Gun','Gold Disc of Death'],
['#46','2772','Commander','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Yellow Galactic Gun','Gold Disc of Death'],
['#47','2655','Commander','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Blue Galactic Gun','Gold Disc of Death'],
['#48','2078','Commander','Rock','Inky','Red Mohawk','Purple Chain','Yellow Galactic Gun','Gold Disc of Death'],
['#49','3023','Commander','Retro','Mango Gradient','White Headphones','Purple Walkman','Yellow Galactic Gun','Gold Disc of Death'],
['#50','1252','Commander','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Yellow Galactic Gun','Gold Disc of Death'],
['#51','1135','Commander','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Blue Galactic Gun','Gold Disc of Death'],
['#52','1201','Commander','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Yellow Galactic Gun','Gold Disc of Death'],
['#53','2148','Commander','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Blue Galactic Gun','Blue Disc of Death'],
['#54','3162','Commander','Rock','Inky','Red Mohawk','White Chain','Green Galactic Gun','Blue Disc of Death'],
['#55','1971','Commander','Retro','Lime Gradient','Green Headphones','Purple Walkman','Yellow Galactic Gun','Blue Disc of Death'],
['#56','823','Commander','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Blue Galactic Gun','Blue Disc of Death'],
['#57','1242','Commander','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Blue Galactic Gun','Blue Disc of Death'],
['#58','2688','Commander','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Gold Galactic Gun','Green Disc of Death'],
['#59','2728','Commander','Rock','Plum','Gold Mohawk','Purple Chain','Blue Galactic Gun','Green Disc of Death'],
['#60','1956','Commander','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Yellow Galactic Gun','Green Disc of Death'],
['#61','64','Commander','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Green Galactic Gun','Green Disc of Death'],
['#62','2289','Commander','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Green Galactic Gun','Green Disc of Death'],
['#63','2270','Commander','Cyberpunk','Monster Green','Green Mask','Green Earphones','Blue Galactic Gun','Green Disc of Death'],
['#64','3105','Commander','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Gold Galactic Gun','Red Disc of Death'],
['#65','3358','Commander','Rock','Charcoal','Red Mohawk','Gold Chain','Blue Galactic Gun','Red Disc of Death'],
['#66','1239','Commander','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Blue Galactic Gun','Red Disc of Death'],
['#67','3447','Commander','Retro','Lime Gradient','Green Headphones','White Walkman','Green Galactic Gun','Red Disc of Death'],
['#68','2958','Commander','Retro','Lime Gradient','Green Headphones','Orange Walkman','Blue Galactic Gun','Red Disc of Death'],
['#69','399','Commander','Retro','Mango Gradient','Green Headphones','Orange Walkman','Blue Galactic Gun','Red Disc of Death'],
['#70','437','Commander','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Green Galactic Gun','Red Disc of Death'],
['#71','2919','Commander','Rock','Inky','Gold Mohawk','Purple Chain','Blue Galactic Gun','Pink Disc of Death'],
['#72','1555','Commander','Rock','Charcoal','Gold Mohawk','Purple Chain','Yellow Galactic Gun','Pink Disc of Death'],
['#73','3373','Commander','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Gold Galactic Gun','Pink Disc of Death'],
['#74','258','Commander','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Gold Galactic Gun','Pink Disc of Death'],
['#75','2756','Commander','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Green Galactic Gun','Pink Disc of Death'],
['#76','196','Commander','Retro','Lavender Gradient','White Headphones','White Walkman','Green Galactic Gun','Pink Disc of Death'],
['#77','3244','Commander','Retro','Lime Gradient','Blue Headphones','White Walkman','Yellow Galactic Gun','Pink Disc of Death'],
['#78','489','Commander','Rock','Charcoal','Purple Mohawk','Red Chain','Yellow Galactic Gun','Pink Disc of Death'],
['#79','874','Commander','Rock','Inky','Green Mohawk','Gold Chain','Gold Galactic Gun','Purple Disc of Death'],
['#80','181','Commander','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Blue Galactic Gun','Purple Disc of Death'],
['#81','2264','Commander','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Yellow Galactic Gun','Purple Disc of Death'],
['#82','582','Commander','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Gold Galactic Gun','Purple Disc of Death'],
['#83','166','Commander','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Gold Galactic Gun','Purple Disc of Death'],
['#84','1179','Commander','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Green Galactic Gun','Purple Disc of Death'],
['#85','2878','Commander','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Green Galactic Gun','Purple Disc of Death'],
['#86','3120','Commander','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Blue Galactic Gun','Purple Disc of Death'],
['#87','2091','Commander','Rock','Inky','Purple Mohawk','White Chain','Yellow Galactic Gun','Purple Disc of Death'],
['#88','1644','Commander','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Green Galactic Gun','Turquoise Disc of Death'],
['#89','864','Commander','Hippie','Blush','Gold Head Garland','Green Sling Bag','Blue Galactic Gun','Turquoise Disc of Death'],
['#90','2128','Commander','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Green Galactic Gun','Turquoise Disc of Death'],
['#91','130','Commander','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Gold Galactic Gun','Turquoise Disc of Death'],
['#92','865','Commander','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Gold Galactic Gun','Turquoise Disc of Death'],
['#93','2514','Commander','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Galactic Gun','Turquoise Disc of Death'],
['#94','2868','Commander','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Green Galactic Gun','Turquoise Disc of Death'],
['#95','2182','Commander','Retro','Lime Gradient','Green Headphones','Orange Walkman','Blue Galactic Gun','Turquoise Disc of Death'],
['#96','403','Commander','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Blue Galactic Gun','Turquoise Disc of Death'],
['#97','2047','Commander','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Yellow Galactic Gun','Turquoise Disc of Death'],
['#98','2877','Sergeant','Rock','Charcoal','Gold Mohawk','Gold Chain','Purple Trooper Revolver','Gold Diamond Axe'],
['#99','3395','Sergeant','Rock','Charcoal','Gold Mohawk','Gold Chain','Green Trooper Revolver','Gold Diamond Axe'],
['#100','580','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Gold Trooper Revolver','Gold Diamond Axe'],
['#101','971','Sergeant','Rock','Inky','Gold Mohawk','Gold Chain','Gold Trooper Revolver','Pink Diamond Axe'],
['#102','2240','Sergeant','Rock','Inky','Gold Mohawk','Purple Chain','Gold Trooper Revolver','Gold Diamond Axe'],
['#103','781','Sergeant','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Red Trooper Revolver','Gold Diamond Axe'],
['#104','1526','Sergeant','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Blue Trooper Revolver','Gold Diamond Axe'],
['#105','1313','Sergeant','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Gold Trooper Revolver','Purple Diamond Axe'],
['#106','2210','Sergeant','Rock','Inky','Gold Mohawk','Gold Chain','Purple Trooper Revolver','Green Diamond Axe'],
['#107','1541','Sergeant','Rock','Plum','Gold Mohawk','Gold Chain','Green Trooper Revolver','Turquoise Diamond Axe'],
['#108','3248','Sergeant','Rock','Inky','Gold Mohawk','Purple Chain','Red Trooper Revolver','Gold Diamond Axe'],
['#109','3240','Sergeant','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Purple Trooper Revolver','Blue Diamond Axe'],
['#110','1223','Sergeant','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Green Trooper Revolver','Pink Diamond Axe'],
['#111','393','Sergeant','Rock','Charcoal','Gold Mohawk','Red Chain','Gold Trooper Revolver','Blue Diamond Axe'],
['#112','2654','Sergeant','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Green Trooper Revolver','Green Diamond Axe'],
['#113','2846','Sergeant','Rock','Plum','Gold Mohawk','White Chain','Red Trooper Revolver','Gold Diamond Axe'],
['#114','171','Sergeant','Retro','Lavender Gradient','Gold Headphones','White Walkman','Red Trooper Revolver','Gold Diamond Axe'],
['#115','685','Sergeant','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Green Trooper Revolver','Gold Diamond Axe'],
['#116','778','Sergeant','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Red Trooper Revolver','Green Diamond Axe'],
['#117','1802','Sergeant','Rock','Charcoal','Gold Mohawk','White Chain','Gold Trooper Revolver','Green Diamond Axe'],
['#118','2291','Sergeant','Retro','Lime Gradient','Gold Headphones','White Walkman','Blue Trooper Revolver','Gold Diamond Axe'],
['#119','2387','Sergeant','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Gold Trooper Revolver','Green Diamond Axe'],
['#120','2973','Sergeant','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Purple Trooper Revolver','Gold Diamond Axe'],
['#121','2311','Sergeant','Rock','Plum','Green Mohawk','Gold Chain','Gold Trooper Revolver','Pink Diamond Axe'],
['#122','2924','Sergeant','Rock','Inky','Purple Mohawk','Gold Chain','Gold Trooper Revolver','Pink Diamond Axe'],
['#123','2506','Sergeant','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Red Trooper Revolver','Gold Diamond Axe'],
['#124','2695','Sergeant','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Blue Trooper Revolver','Gold Diamond Axe'],
['#125','2235','Sergeant','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Purple Trooper Revolver','Green Diamond Axe'],
['#126','608','Sergeant','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Gold Trooper Revolver','Purple Diamond Axe'],
['#127','265','Sergeant','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Green Trooper Revolver','Gold Diamond Axe'],
['#128','1011','Sergeant','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Green Trooper Revolver','Gold Diamond Axe'],
['#129','1994','Sergeant','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Purple Trooper Revolver','Gold Diamond Axe'],
['#130','1057','Sergeant','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Purple Trooper Revolver','Gold Diamond Axe'],
['#131','283','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Purple Trooper Revolver','Gold Diamond Axe'],
['#132','1616','Sergeant','Rock','Charcoal','Gold Mohawk','Purple Chain','Green Trooper Revolver','Pink Diamond Axe'],
['#133','2226','Sergeant','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Gold Trooper Revolver','Green Diamond Axe'],
['#134','1406','Sergeant','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Blue Trooper Revolver','Gold Diamond Axe'],
['#135','2522','Sergeant','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Gold Trooper Revolver','Pink Diamond Axe'],
['#136','3267','Sergeant','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Gold Trooper Revolver','Green Diamond Axe'],
['#137','2515','Sergeant','Rock','Inky','Gold Mohawk','White Chain','Purple Trooper Revolver','Purple Diamond Axe'],
['#138','1248','Sergeant','Rock','Plum','Gold Mohawk','Purple Chain','Blue Trooper Revolver','Green Diamond Axe'],
['#139','458','Sergeant','Rock','Inky','Gold Mohawk','Purple Chain','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#140','1407','Sergeant','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Purple Trooper Revolver','Green Diamond Axe'],
['#141','3123','Sergeant','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Purple Trooper Revolver','Green Diamond Axe'],
['#142','2205','Sergeant','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#143','930','Sergeant','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Gold Trooper Revolver','Purple Diamond Axe'],
['#144','2491','Sergeant','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Gold Trooper Revolver','Purple Diamond Axe'],
['#145','123','Sergeant','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Purple Trooper Revolver','Pink Diamond Axe'],
['#146','198','Sergeant','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Purple Trooper Revolver','Pink Diamond Axe'],
['#147','1306','Sergeant','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Green Trooper Revolver','Pink Diamond Axe'],
['#148','1409','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Gold Trooper Revolver','Gold Diamond Axe'],
['#149','570','Sergeant','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Trooper Revolver','Gold Diamond Axe'],
['#150','3262','Sergeant','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Green Trooper Revolver','Purple Diamond Axe'],
['#151','1467','Sergeant','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Blue Trooper Revolver','Blue Diamond Axe'],
['#152','3416','Sergeant','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Red Trooper Revolver','Green Diamond Axe'],
['#153','1940','Sergeant','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Red Trooper Revolver','Green Diamond Axe'],
['#154','1047','Sergeant','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Green Trooper Revolver','Pink Diamond Axe'],
['#155','224','Sergeant','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#156','769','Sergeant','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Red Trooper Revolver','Purple Diamond Axe'],
['#157','2936','Sergeant','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Purple Trooper Revolver','Green Diamond Axe'],
['#158','2716','Sergeant','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Blue Trooper Revolver','Pink Diamond Axe'],
['#159','36','Sergeant','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Blue Trooper Revolver','Pink Diamond Axe'],
['#160','395','Sergeant','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Blue Trooper Revolver','Pink Diamond Axe'],
['#161','1510','Sergeant','Rock','Plum','Green Mohawk','Gold Chain','Green Trooper Revolver','Blue Diamond Axe'],
['#162','3196','Sergeant','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Blue Trooper Revolver','Green Diamond Axe'],
['#163','2237','Sergeant','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Blue Trooper Revolver','Purple Diamond Axe'],
['#164','1189','Sergeant','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Blue Trooper Revolver','Purple Diamond Axe'],
['#165','1101','Sergeant','Retro','Mango Gradient','White Headphones','Gold Walkman','Purple Trooper Revolver','Green Diamond Axe'],
['#166','1761','Sergeant','Retro','Lime Gradient','White Headphones','Gold Walkman','Green Trooper Revolver','Pink Diamond Axe'],
['#167','2048','Sergeant','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Red Trooper Revolver','Blue Diamond Axe'],
['#168','3017','Sergeant','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Red Trooper Revolver','Purple Diamond Axe'],
['#169','527','Sergeant','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#170','1063','Sergeant','Hippie','Blush','Gold Head Garland','Green Sling Bag','Red Trooper Revolver','Green Diamond Axe'],
['#171','1200','Sergeant','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Trooper Revolver','Purple Diamond Axe'],
['#172','494','Sergeant','Rock','Charcoal','Purple Mohawk','Gold Chain','Red Trooper Revolver','Green Diamond Axe'],
['#173','311','Sergeant','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Purple Trooper Revolver','Purple Diamond Axe'],
['#174','3429','Sergeant','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Purple Trooper Revolver','Purple Diamond Axe'],
['#175','1775','Sergeant','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Red Trooper Revolver','Pink Diamond Axe'],
['#176','208','Sergeant','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#177','32','Sergeant','Retro','Lime Gradient','White Headphones','Gold Walkman','Red Trooper Revolver','Blue Diamond Axe'],
['#178','1077','Sergeant','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Purple Trooper Revolver','Pink Diamond Axe'],
['#179','354','Sergeant','Retro','Lime Gradient','Green Headphones','Gold Walkman','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#180','3396','Sergeant','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Green Trooper Revolver','Gold Diamond Axe'],
['#181','709','Sergeant','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Red Trooper Revolver','Gold Diamond Axe'],
['#182','719','Sergeant','Rock','Inky','Purple Mohawk','Gold Chain','Blue Trooper Revolver','Blue Diamond Axe'],
['#183','519','Sergeant','Rock','Inky','Red Mohawk','Red Chain','Gold Trooper Revolver','Green Diamond Axe'],
['#184','2347','Sergeant','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Purple Trooper Revolver','Purple Diamond Axe'],
['#185','3229','Sergeant','Rock','Inky','Purple Mohawk','Purple Chain','Red Trooper Revolver','Gold Diamond Axe'],
['#186','1778','Sergeant','Rock','Plum','Green Mohawk','Red Chain','Red Trooper Revolver','Gold Diamond Axe'],
['#187','457','Sergeant','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Red Trooper Revolver','Turquoise Diamond Axe'],
['#188','1886','Sergeant','Rock','Plum','Green Mohawk','Purple Chain','Blue Trooper Revolver','Gold Diamond Axe'],
['#189','2955','Sergeant','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Blue Trooper Revolver','Blue Diamond Axe'],
['#190','470','Sergeant','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Red Trooper Revolver','Purple Diamond Axe'],
['#191','180','Sergeant','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Purple Trooper Revolver','Gold Diamond Axe'],
['#192','1347','Sergeant','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Purple Trooper Revolver','Pink Diamond Axe'],
['#193','788','Sergeant','Retro','Mango Gradient','Green Headphones','Gold Walkman','Red Trooper Revolver','Blue Diamond Axe'],
['#194','3461','Sergeant','Retro','Lime Gradient','White Headphones','Gold Walkman','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#195','1743','Sergeant','Retro','Mango Gradient','White Headphones','Purple Walkman','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#196','1233','Sergeant','Rock','Charcoal','Green Mohawk','Purple Chain','Gold Trooper Revolver','Pink Diamond Axe'],
['#197','2232','Sergeant','Retro','Lime Gradient','White Headphones','White Walkman','Gold Trooper Revolver','Purple Diamond Axe'],
['#198','1776','Sergeant','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#199','613','Sergeant','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Red Trooper Revolver','Turquoise Diamond Axe'],
['#200','939','Sergeant','Retro','Mango Gradient','Blue Headphones','White Walkman','Gold Trooper Revolver','Pink Diamond Axe'],
['#201','990','Sergeant','Rock','Plum','Red Mohawk','White Chain','Gold Trooper Revolver','Pink Diamond Axe'],
['#202','1986','Sergeant','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Blue Trooper Revolver','Purple Diamond Axe'],
['#203','1378','Sergeant','Retro','Lavender Gradient','White Headphones','Orange Walkman','Gold Trooper Revolver','Purple Diamond Axe'],
['#204','969','Sergeant','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Blue Trooper Revolver','Pink Diamond Axe'],
['#205','1924','Sergeant','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Blue Trooper Revolver','Blue Diamond Axe'],
['#206','390','Sergeant','Retro','Lavender Gradient','Green Headphones','White Walkman','Gold Trooper Revolver','Green Diamond Axe'],
['#207','359','Sergeant','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Purple Trooper Revolver','Gold Diamond Axe'],
['#208','751','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Green Trooper Revolver','Turquoise Diamond Axe'],
['#209','2319','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Green Trooper Revolver','Turquoise Diamond Axe'],
['#210','236','Sergeant','Retro','Mango Gradient','Green Headphones','White Walkman','Gold Trooper Revolver','Purple Diamond Axe'],
['#211','2591','Sergeant','Rock','Inky','Purple Mohawk','Red Chain','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#212','2770','Sergeant','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Red Trooper Revolver','Pink Diamond Axe'],
['#213','849','Sergeant','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Red Trooper Revolver','Pink Diamond Axe'],
['#214','738','Sergeant','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Red Trooper Revolver','Blue Diamond Axe'],
['#215','2271','Sergeant','Rock','Plum','Purple Mohawk','Red Chain','Gold Trooper Revolver','Purple Diamond Axe'],
['#216','821','Sergeant','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Red Trooper Revolver','Purple Diamond Axe'],
['#217','1640','Sergeant','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#218','2022','Sergeant','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Red Trooper Revolver','Turquoise Diamond Axe'],
['#219','1847','Sergeant','Cyberpunk','Marigold','Green Mask','Gold Earphones','Red Trooper Revolver','Purple Diamond Axe'],
['#220','334','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Purple Trooper Revolver','Blue Diamond Axe'],
['#221','3019','Sergeant','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Gold Trooper Revolver','Blue Diamond Axe'],
['#222','2746','Sergeant','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Red Trooper Revolver','Gold Diamond Axe'],
['#223','2634','Sergeant','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#224','42','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Green Trooper Revolver','Pink Diamond Axe'],
['#225','477','Sergeant','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Blue Trooper Revolver','Gold Diamond Axe'],
['#226','1633','Sergeant','Rock','Charcoal','Green Mohawk','White Chain','Gold Trooper Revolver','Pink Diamond Axe'],
['#227','364','Sergeant','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Red Trooper Revolver','Gold Diamond Axe'],
['#228','2405','Sergeant','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Blue Trooper Revolver','Blue Diamond Axe'],
['#229','2076','Sergeant','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Purple Trooper Revolver','Purple Diamond Axe'],
['#230','1840','Sergeant','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#231','2723','Sergeant','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Green Trooper Revolver','Purple Diamond Axe'],
['#232','2850','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Blue Trooper Revolver','Green Diamond Axe'],
['#233','2511','Sergeant','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Green Trooper Revolver','Blue Diamond Axe'],
['#234','1607','Sergeant','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Gold Trooper Revolver','Purple Diamond Axe'],
['#235','2335','Sergeant','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Red Trooper Revolver','Blue Diamond Axe'],
['#236','1791','Sergeant','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Green Trooper Revolver','Gold Diamond Axe'],
['#237','1603','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Red Trooper Revolver','Purple Diamond Axe'],
['#238','1041','Sergeant','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#239','549','Sergeant','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Red Trooper Revolver','Green Diamond Axe'],
['#240','2426','Sergeant','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#241','924','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Green Trooper Revolver','Green Diamond Axe'],
['#242','1533','Sergeant','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Gold Trooper Revolver','Green Diamond Axe'],
['#243','3332','Sergeant','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Gold Trooper Revolver','Pink Diamond Axe'],
['#244','2553','Sergeant','Hippie','Blush','Pink Head Garland','Green Sling Bag','Blue Trooper Revolver','Gold Diamond Axe'],
['#245','3467','Sergeant','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Blue Trooper Revolver','Blue Diamond Axe'],
['#246','929','Sergeant','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Gold Trooper Revolver','Green Diamond Axe'],
['#247','3484','Sergeant','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Purple Trooper Revolver','Gold Diamond Axe'],
['#248','2035','Sergeant','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Purple Trooper Revolver','Gold Diamond Axe'],
['#249','1679','Sergeant','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Blue Trooper Revolver','Purple Diamond Axe'],
['#250','3126','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Red Trooper Revolver','Turquoise Diamond Axe'],
['#251','3096','Sergeant','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Green Trooper Revolver','Gold Diamond Axe'],
['#252','514','Sergeant','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Gold Trooper Revolver','Blue Diamond Axe'],
['#253','834','Sergeant','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Gold Trooper Revolver','Turquoise Diamond Axe'],
['#254','3078','Sergeant','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#255','2036','Sergeant','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Blue Trooper Revolver','Pink Diamond Axe'],
['#256','2859','Sergeant','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Gold Trooper Revolver','Pink Diamond Axe'],
['#257','3372','Sergeant','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Gold Trooper Revolver','Purple Diamond Axe'],
['#258','2449','Sergeant','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Gold Trooper Revolver','Blue Diamond Axe'],
['#259','398','Sergeant','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Trooper Revolver','Pink Diamond Axe'],
['#260','279','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Gold Trooper Revolver','Purple Diamond Axe'],
['#261','327','Sergeant','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Gold Trooper Revolver','Purple Diamond Axe'],
['#262','1123','Sergeant','Retro','Lavender Gradient','White Headphones','Purple Walkman','Purple Trooper Revolver','Green Diamond Axe'],
['#263','1412','Sergeant','Rock','Inky','Green Mohawk','Purple Chain','Purple Trooper Revolver','Green Diamond Axe'],
['#264','1270','Sergeant','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Purple Trooper Revolver','Blue Diamond Axe'],
['#265','741','Sergeant','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Green Trooper Revolver','Pink Diamond Axe'],
['#266','687','Sergeant','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Purple Trooper Revolver','Purple Diamond Axe'],
['#267','1425','Sergeant','Rock','Charcoal','Green Mohawk','Red Chain','Purple Trooper Revolver','Blue Diamond Axe'],
['#268','2834','Sergeant','Retro','Lime Gradient','Blue Headphones','White Walkman','Green Trooper Revolver','Pink Diamond Axe'],
['#269','548','Sergeant','Rock','Charcoal','Red Mohawk','Purple Chain','Blue Trooper Revolver','Blue Diamond Axe'],
['#270','263','Sergeant','Rock','Inky','Red Mohawk','White Chain','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#271','2298','Sergeant','Retro','Mango Gradient','Blue Headphones','White Walkman','Purple Trooper Revolver','Purple Diamond Axe'],
['#272','2063','Sergeant','Retro','Lime Gradient','Green Headphones','Purple Walkman','Purple Trooper Revolver','Pink Diamond Axe'],
['#273','915','Sergeant','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Purple Trooper Revolver','Pink Diamond Axe'],
['#274','60','Sergeant','Rock','Inky','Purple Mohawk','Purple Chain','Green Trooper Revolver','Green Diamond Axe'],
['#275','2391','Sergeant','Rock','Inky','Red Mohawk','White Chain','Green Trooper Revolver','Turquoise Diamond Axe'],
['#276','2545','Sergeant','Retro','Lime Gradient','White Headphones','Orange Walkman','Green Trooper Revolver','Green Diamond Axe'],
['#277','2734','Sergeant','Rock','Plum','Green Mohawk','Red Chain','Green Trooper Revolver','Turquoise Diamond Axe'],
['#278','642','Sergeant','Retro','Lavender Gradient','White Headphones','White Walkman','Red Trooper Revolver','Blue Diamond Axe'],
['#279','2851','Sergeant','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Purple Trooper Revolver','Pink Diamond Axe'],
['#280','981','Sergeant','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Green Trooper Revolver','Green Diamond Axe'],
['#281','728','Sergeant','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Purple Trooper Revolver','Pink Diamond Axe'],
['#282','2254','Sergeant','Rock','Inky','Green Mohawk','Purple Chain','Red Trooper Revolver','Purple Diamond Axe'],
['#283','1408','Sergeant','Retro','Lime Gradient','Green Headphones','Orange Walkman','Purple Trooper Revolver','Blue Diamond Axe'],
['#284','274','Sergeant','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#285','1534','Sergeant','Retro','Mango Gradient','Blue Headphones','White Walkman','Red Trooper Revolver','Turquoise Diamond Axe'],
['#286','1229','Sergeant','Retro','Mango Gradient','Blue Headphones','White Walkman','Red Trooper Revolver','Purple Diamond Axe'],
['#287','2752','Sergeant','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#288','3052','Sergeant','Rock','Charcoal','Green Mohawk','White Chain','Purple Trooper Revolver','Pink Diamond Axe'],
['#289','1182','Sergeant','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Red Trooper Revolver','Pink Diamond Axe'],
['#290','987','Sergeant','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Green Trooper Revolver','Green Diamond Axe'],
['#291','1150','Sergeant','Rock','Inky','Purple Mohawk','Purple Chain','Red Trooper Revolver','Purple Diamond Axe'],
['#292','1588','Sergeant','Retro','Lime Gradient','White Headphones','Orange Walkman','Red Trooper Revolver','Turquoise Diamond Axe'],
['#293','1500','Sergeant','Rock','Plum','Purple Mohawk','Red Chain','Red Trooper Revolver','Blue Diamond Axe'],
['#294','2130','Sergeant','Rock','Charcoal','Green Mohawk','White Chain','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#295','1645','Sergeant','Retro','Mango Gradient','Green Headphones','Orange Walkman','Green Trooper Revolver','Purple Diamond Axe'],
['#296','1938','Sergeant','Rock','Charcoal','Purple Mohawk','Red Chain','Red Trooper Revolver','Green Diamond Axe'],
['#297','1420','Sergeant','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Purple Trooper Revolver','Purple Diamond Axe'],
['#298','2508','Sergeant','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Purple Trooper Revolver','Purple Diamond Axe'],
['#299','1365','Sergeant','Rock','Inky','Green Mohawk','Purple Chain','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#300','391','Sergeant','Rock','Charcoal','Purple Mohawk','Red Chain','Red Trooper Revolver','Turquoise Diamond Axe'],
['#301','2453','Sergeant','Rock','Charcoal','Green Mohawk','Red Chain','Blue Trooper Revolver','Blue Diamond Axe'],
['#302','1092','Sergeant','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Purple Trooper Revolver','Pink Diamond Axe'],
['#303','1265','Sergeant','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Green Trooper Revolver','Turquoise Diamond Axe'],
['#304','1962','Sergeant','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Purple Trooper Revolver','Blue Diamond Axe'],
['#305','1395','Sergeant','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Green Trooper Revolver','Pink Diamond Axe'],
['#306','1232','Sergeant','Retro','Mango Gradient','Green Headphones','White Walkman','Blue Trooper Revolver','Pink Diamond Axe'],
['#307','542','Sergeant','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Green Trooper Revolver','Blue Diamond Axe'],
['#308','1666','Sergeant','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#309','3013','Sergeant','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Green Trooper Revolver','Green Diamond Axe'],
['#310','957','Sergeant','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Blue Trooper Revolver','Pink Diamond Axe'],
['#311','2950','Sergeant','Cyberpunk','Marigold','Green Mask','Blue Earphones','Red Trooper Revolver','Pink Diamond Axe'],
['#312','3402','Sergeant','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Red Trooper Revolver','Pink Diamond Axe'],
['#313','2340','Sergeant','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Trooper Revolver','Blue Diamond Axe'],
['#314','541','Sergeant','Rock','Inky','Purple Mohawk','Red Chain','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#315','1769','Sergeant','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Purple Trooper Revolver','Purple Diamond Axe'],
['#316','1501','Sergeant','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Red Trooper Revolver','Purple Diamond Axe'],
['#317','3140','Sergeant','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Purple Trooper Revolver','Blue Diamond Axe'],
['#318','2068','Sergeant','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Green Trooper Revolver','Pink Diamond Axe'],
['#319','1851','Sergeant','Rock','Plum','Purple Mohawk','White Chain','Red Trooper Revolver','Purple Diamond Axe'],
['#320','2428','Sergeant','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Green Trooper Revolver','Pink Diamond Axe'],
['#321','2875','Sergeant','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Green Trooper Revolver','Pink Diamond Axe'],
['#322','2069','Sergeant','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Blue Trooper Revolver','Pink Diamond Axe'],
['#323','1098','Sergeant','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Purple Trooper Revolver','Blue Diamond Axe'],
['#324','3001','Sergeant','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Red Trooper Revolver','Green Diamond Axe'],
['#325','3112','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Green Trooper Revolver','Pink Diamond Axe'],
['#326','1012','Sergeant','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Blue Trooper Revolver','Green Diamond Axe'],
['#327','2377','Sergeant','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Blue Trooper Revolver','Green Diamond Axe'],
['#328','154','Sergeant','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Purple Trooper Revolver','Pink Diamond Axe'],
['#329','652','Sergeant','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Green Trooper Revolver','Pink Diamond Axe'],
['#330','1525','Sergeant','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Green Trooper Revolver','Pink Diamond Axe'],
['#331','2299','Sergeant','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Blue Trooper Revolver','Pink Diamond Axe'],
['#332','978','Sergeant','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Blue Trooper Revolver','Green Diamond Axe'],
['#333','1281','Sergeant','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#334','1908','Sergeant','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Red Trooper Revolver','Green Diamond Axe'],
['#335','201','Sergeant','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Red Trooper Revolver','Purple Diamond Axe'],
['#336','2536','Sergeant','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#337','791','Sergeant','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Blue Trooper Revolver','Purple Diamond Axe'],
['#338','336','Sergeant','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Red Trooper Revolver','Turquoise Diamond Axe'],
['#339','1804','Sergeant','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#340','1671','Sergeant','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Green Trooper Revolver','Green Diamond Axe'],
['#341','1656','Sergeant','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Red Trooper Revolver','Turquoise Diamond Axe'],
['#342','2660','Sergeant','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Blue Trooper Revolver','Blue Diamond Axe'],
['#343','814','Sergeant','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#344','160','Sergeant','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Red Trooper Revolver','Green Diamond Axe'],
['#345','273','Sergeant','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Blue Trooper Revolver','Purple Diamond Axe'],
['#346','3316','Sergeant','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Red Trooper Revolver','Turquoise Diamond Axe'],
['#347','639','Sergeant','Cyberpunk','Monster Green','Green Mask','Green Earphones','Red Trooper Revolver','Purple Diamond Axe'],
['#348','3183','Sergeant','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#349','2329','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Green Trooper Revolver','Purple Diamond Axe'],
['#350','1312','Sergeant','Cyberpunk','Marigold','Blue Mask','Green Earphones','Blue Trooper Revolver','Blue Diamond Axe'],
['#351','1932','Sergeant','Cyberpunk','Monster Green','Green Mask','Green Earphones','Blue Trooper Revolver','Blue Diamond Axe'],
['#352','2979','Sergeant','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Purple Trooper Revolver','Purple Diamond Axe'],
['#353','2581','Sergeant','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Purple Trooper Revolver','Purple Diamond Axe'],
['#354','2147','Sergeant','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Blue Trooper Revolver','Green Diamond Axe'],
['#355','1415','Sergeant','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Purple Trooper Revolver','Turquoise Diamond Axe'],
['#356','406','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Green Trooper Revolver','Purple Diamond Axe'],
['#357','3327','Sergeant','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Red Trooper Revolver','Blue Diamond Axe'],
['#358','2764','Sergeant','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Red Trooper Revolver','Turquoise Diamond Axe'],
['#359','1377','Sergeant','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Blue Trooper Revolver','Blue Diamond Axe'],
['#360','3185','Sergeant','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Blue Trooper Revolver','Blue Diamond Axe'],
['#361','2680','Sergeant','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Blue Trooper Revolver','Pink Diamond Axe'],
['#362','2354','Sergeant','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Blue Trooper Revolver','Turquoise Diamond Axe'],
['#363','3076','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#364','3361','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#365','2977','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Gold Classic Colt','Pink Diamond Axe'],
['#366','2915','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Gold Classic Colt','Blue Diamond Axe'],
['#367','1725','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Gold Classic Colt','Green Diamond Axe'],
['#368','2594','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Gold Classic Colt','Purple Diamond Axe'],
['#369','1186','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Gold Classic Colt','Gold Diamond Axe'],
['#370','3085','Trooper','Rock','Inky','Gold Mohawk','White Chain','Gold Classic Colt','Gold Diamond Axe'],
['#371','1816','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#372','2914','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#373','1386','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Purple Classic Colt','Gold Diamond Axe'],
['#374','1868','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#375','1914','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#376','645','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#377','387','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#378','848','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#379','656','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#380','316','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#381','2715','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#382','3119','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Gold Classic Colt','Green Diamond Axe'],
['#383','1218','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#384','2479','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#385','3249','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#386','492','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#387','1464','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#388','2219','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#389','2371','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#390','1892','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Gold Classic Colt','Gold Diamond Axe'],
['#391','1112','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#392','2912','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#393','1060','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#394','697','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#395','2112','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#396','2523','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#397','172','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Gold Classic Colt','Gold Diamond Axe'],
['#398','3188','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#399','2574','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#400','2370','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#401','148','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#402','2659','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#403','1384','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#404','3028','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#405','3200','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#406','579','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#407','3108','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#408','2521','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#409','200','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#410','2060','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#411','529','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#412','1511','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#413','2855','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#414','3025','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#415','2709','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#416','3480','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#417','1329','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#418','2526','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#419','3206','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#420','1046','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#421','2910','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#422','1188','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#423','1024','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#424','2579','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#425','2544','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#426','1355','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#427','2562','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#428','3216','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#429','3056','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#430','1824','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#431','2838','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#432','29','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#433','2517','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#434','2144','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#435','2496','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#436','1537','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#437','339','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#438','2256','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Gold Classic Colt','Pink Diamond Axe'],
['#439','965','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Gold Classic Colt','Green Diamond Axe'],
['#440','2071','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Gold Classic Colt','Green Diamond Axe'],
['#441','2940','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#442','2556','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Gold Classic Colt','Pink Diamond Axe'],
['#443','2964','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Gold Classic Colt','Blue Diamond Axe'],
['#444','1309','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Gold Classic Colt','Green Diamond Axe'],
['#445','1160','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Gold Classic Colt','Green Diamond Axe'],
['#446','125','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#447','2897','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#448','50','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Gold Classic Colt','Blue Diamond Axe'],
['#449','1516','Trooper','Rock','Plum','Gold Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#450','1515','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Gold Classic Colt','Turquoise Diamond Axe'],
['#451','1734','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#452','1878','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#453','159','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#454','1488','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#455','2285','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#456','2163','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Purple Classic Colt','Pink Diamond Axe'],
['#457','1991','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#458','3456','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Purple Classic Colt','Blue Diamond Axe'],
['#459','1417','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Purple Classic Colt','Blue Diamond Axe'],
['#460','2671','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#461','153','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#462','666','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#463','2576','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#464','847','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#465','3259','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#466','3232','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#467','1288','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#468','1681','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#469','521','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#470','41','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#471','269','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#472','1894','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#473','551','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#474','230','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#475','1650','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#476','625','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#477','1653','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#478','3179','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Pink Classic Colt','Pink Diamond Axe'],
['#479','2682','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Gold Classic Colt','Green Diamond Axe'],
['#480','2623','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Gold Classic Colt','Pink Diamond Axe'],
['#481','158','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Purple Classic Colt','Gold Diamond Axe'],
['#482','1157','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#483','3360','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#484','3321','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#485','2045','Trooper','Rock','Inky','Gold Mohawk','Gold Chain','Pink Classic Colt','Green Diamond Axe'],
['#486','908','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#487','3030','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#488','500','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#489','886','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Gold Classic Colt','Green Diamond Axe'],
['#490','777','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#491','3158','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Gold Classic Colt','Green Diamond Axe'],
['#492','2227','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#493','2796','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Gold Classic Colt','Turquoise Diamond Axe'],
['#494','1538','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#495','1982','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#496','3098','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#497','2502','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Gold Classic Colt','Purple Diamond Axe'],
['#498','1257','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#499','516','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#500','3020','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#501','1016','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Purple Classic Colt','Gold Diamond Axe'],
['#502','1126','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#503','3082','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#504','2789','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#505','1793','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Pink Classic Colt','Gold Diamond Axe'],
['#506','1576','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#507','1693','Trooper','Rock','Charcoal','Gold Mohawk','Gold Chain','Green Classic Colt','Purple Diamond Axe'],
['#508','2804','Trooper','Rock','Plum','Gold Mohawk','Gold Chain','Green Classic Colt','Purple Diamond Axe'],
['#509','194','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Pink Classic Colt','Gold Diamond Axe'],
['#510','255','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Pink Classic Colt','Gold Diamond Axe'],
['#511','2637','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#512','1764','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#513','2620','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Gold Classic Colt','Pink Diamond Axe'],
['#514','1230','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Gold Classic Colt','Blue Diamond Axe'],
['#515','475','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#516','899','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#517','2000','Trooper','Rock','Plum','Gold Mohawk','White Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#518','3275','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Gold Classic Colt','Green Diamond Axe'],
['#519','3397','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Gold Classic Colt','Green Diamond Axe'],
['#520','1979','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#521','2825','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#522','2075','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#523','3225','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#524','3258','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#525','2957','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#526','3469','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Gold Classic Colt','Purple Diamond Axe'],
['#527','1308','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#528','1333','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#529','1358','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#530','979','Trooper','Rock','Plum','Gold Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#531','1372','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#532','2639','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#533','1447','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Gold Classic Colt','Pink Diamond Axe'],
['#534','221','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Gold Classic Colt','Pink Diamond Axe'],
['#535','86','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#536','407','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#537','2116','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#538','2696','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#539','2866','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Gold Classic Colt','Blue Diamond Axe'],
['#540','238','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Green Classic Colt','Gold Diamond Axe'],
['#541','1949','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Gold Classic Colt','Green Diamond Axe'],
['#542','2224','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Gold Classic Colt','Purple Diamond Axe'],
['#543','2173','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#544','3329','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#545','3134','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#546','2397','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#547','1651','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#548','2774','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#549','346','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Gold Classic Colt','Gold Diamond Axe'],
['#550','97','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Gold Classic Colt','Gold Diamond Axe'],
['#551','1752','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#552','139','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#553','2755','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#554','1540','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#555','1738','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#556','2446','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#557','1130','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#558','1517','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#559','237','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#560','1290','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#561','2383','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#562','1275','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#563','3457','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#564','1937','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Gold Classic Colt','Green Diamond Axe'],
['#565','3143','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#566','152','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#567','596','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#568','1922','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#569','3289','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#570','3394','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#571','1036','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Gold Classic Colt','Green Diamond Axe'],
['#572','1552','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#573','998','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#574','2346','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#575','2776','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#576','232','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#577','3406','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#578','1368','Trooper','Rock','Inky','Gold Mohawk','White Chain','Pink Classic Colt','Gold Diamond Axe'],
['#579','383','Trooper','Rock','Plum','Gold Mohawk','White Chain','Pink Classic Colt','Gold Diamond Axe'],
['#580','2559','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#581','3220','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#582','959','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#583','2100','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#584','1212','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#585','2732','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#586','2300','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#587','892','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#588','2017','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#589','1512','Trooper','Rock','Inky','Red Mohawk','White Chain','Gold Classic Colt','Gold Diamond Axe'],
['#590','526','Trooper','Rock','Charcoal','Red Mohawk','White Chain','Gold Classic Colt','Gold Diamond Axe'],
['#591','2543','Trooper','Rock','Charcoal','Red Mohawk','White Chain','Gold Classic Colt','Gold Diamond Axe'],
['#592','3193','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#593','576','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#594','1361','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#595','1241','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#596','210','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#597','3243','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#598','3291','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#599','320','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#600','1858','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#601','2779','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#602','308','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#603','373','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#604','1733','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#605','669','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#606','3462','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#607','1385','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#608','2833','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#609','2248','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#610','1421','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#611','3147','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Gold Classic Colt','Green Diamond Axe'],
['#612','2821','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#613','1587','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#614','607','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#615','3280','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#616','454','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#617','1479','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#618','1410','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#619','1807','Trooper','Rock','Inky','Purple Mohawk','Red Chain','Gold Classic Colt','Gold Diamond Axe'],
['#620','2440','Trooper','Rock','Inky','Purple Mohawk','Red Chain','Gold Classic Colt','Gold Diamond Axe'],
['#621','1598','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#622','928','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#623','88','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#624','2026','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#625','2031','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#626','902','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#627','2372','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#628','2429','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#629','1405','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#630','883','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#631','1604','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Gold Classic Colt','Gold Diamond Axe'],
['#632','1431','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#633','1746','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#634','2664','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#635','1211','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Purple Classic Colt','Gold Diamond Axe'],
['#636','1297','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#637','384','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Purple Classic Colt','Gold Diamond Axe'],
['#638','322','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Purple Classic Colt','Gold Diamond Axe'],
['#639','2337','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Gold Classic Colt','Green Diamond Axe'],
['#640','1767','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#641','1694','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#642','2351','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#643','111','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#644','3037','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#645','920','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#646','763','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#647','2145','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#648','2879','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Pink Classic Colt','Green Diamond Axe'],
['#649','760','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#650','841','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#651','581','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#652','1348','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#653','3102','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#654','797','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#655','34','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#656','2731','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#657','1156','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#658','2020','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#659','1370','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#660','3005','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#661','2627','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Purple Classic Colt','Green Diamond Axe'],
['#662','78','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#663','2006','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#664','2028','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#665','962','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#666','3422','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#667','353','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#668','1155','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#669','2215','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#670','184','Trooper','Rock','Inky','Green Mohawk','White Chain','Gold Classic Colt','Gold Diamond Axe'],
['#671','2799','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#672','377','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#673','2954','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#674','555','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Pink Classic Colt','Gold Diamond Axe'],
['#675','2890','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Pink Classic Colt','Gold Diamond Axe'],
['#676','745','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Gold Diamond Axe'],
['#677','2835','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#678','575','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#679','40','Trooper','Hippie','Glass Green','Gold Head Garland','Gold Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#680','462','Trooper','Hippie','Blush','Gold Head Garland','Gold Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#681','2268','Trooper','Hippie','Lilac','Gold Head Garland','Gold Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#682','1626','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Gold Classic Colt','Gold Diamond Axe'],
['#683','736','Trooper','Retro','Lime Gradient','Gold Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#684','835','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#685','973','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#686','1330','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#687','1811','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#688','631','Trooper','Retro','Lavender Gradient','Gold Headphones','Gold Walkman','Green Classic Colt','Blue Diamond Axe'],
['#689','2823','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#690','2439','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#691','3015','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#692','2345','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#693','1311','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#694','1035','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#695','2747','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#696','2922','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#697','1567','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Gold Classic Colt','Green Diamond Axe'],
['#698','2647','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Gold Classic Colt','Green Diamond Axe'],
['#699','1523','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#700','2807','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#701','1548','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Green Classic Colt','Green Diamond Axe'],
['#702','85','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#703','1972','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#704','2394','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#705','1795','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#706','2094','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#707','931','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Purple Classic Colt','Gold Diamond Axe'],
['#708','545','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#709','267','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#710','765','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#711','554','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#712','988','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#713','1264','Trooper','Retro','Mango Gradient','Gold Headphones','Gold Walkman','Green Classic Colt','Purple Diamond Axe'],
['#714','441','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#715','1338','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#716','1524','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#717','2701','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#718','3202','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#719','1440','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#720','3129','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#721','2246','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#722','264','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#723','1111','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#724','2929','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#725','3440','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#726','1800','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#727','1143','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#728','330','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#729','443','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#730','695','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Green Classic Colt','Gold Diamond Axe'],
['#731','3481','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#732','2348','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#733','2140','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#734','3099','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#735','815','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#736','250','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#737','1345','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#738','1522','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#739','411','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#740','2274','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#741','3412','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#742','2172','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#743','3070','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#744','3455','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#745','2882','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#746','1140','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#747','2707','Trooper','Cyberpunk','Maui Blue','Gold Mask','Gold Earphones','Green Classic Colt','Blue Diamond Axe'],
['#748','1078','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#749','2649','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#750','169','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#751','2786','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#752','1786','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#753','2714','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#754','792','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#755','2390','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#756','2645','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#757','2505','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#758','2893','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#759','2396','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Green Classic Colt','Green Diamond Axe'],
['#760','553','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#761','641','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#762','1762','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#763','714','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#764','3293','Trooper','Cyberpunk','Marigold','Gold Mask','Gold Earphones','Green Classic Colt','Purple Diamond Axe'],
['#765','654','Trooper','Cyberpunk','Monster Green','Gold Mask','Gold Earphones','Green Classic Colt','Purple Diamond Axe'],
['#766','3419','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Pink Classic Colt','Gold Diamond Axe'],
['#767','252','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Pink Classic Colt','Gold Diamond Axe'],
['#768','610','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#769','976','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#770','56','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#771','2403','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#772','1014','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#773','535','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#774','3292','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#775','3093','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#776','1494','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#777','109','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#778','296','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#779','1058','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#780','2317','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#781','453','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#782','1463','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#783','1744','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#784','2927','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#785','99','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#786','2729','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#787','112','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#788','1107','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#789','2997','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#790','1621','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#791','28','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#792','1403','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#793','2251','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Gold Classic Colt','Green Diamond Axe'],
['#794','3084','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Gold Classic Colt','Green Diamond Axe'],
['#795','771','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#796','1301','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#797','794','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#798','2860','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#799','381','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#800','1911','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#801','409','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#802','2759','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#803','2315','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#804','1941','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#805','1642','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#806','3104','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#807','2414','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#808','2575','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#809','1913','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#810','1748','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#811','1134','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#812','1569','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#813','2781','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#814','378','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#815','19','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#816','1042','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#817','350','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#818','552','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Gold Diamond Axe'],
['#819','3087','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#820','3409','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#821','3410','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#822','361','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#823','2829','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Green Classic Colt','Gold Diamond Axe'],
['#824','3022','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#825','3335','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#826','2892','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Gold Classic Colt','Gold Diamond Axe'],
['#827','1677','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#828','2885','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#829','773','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#830','1216','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#831','1250','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#832','11','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#833','1629','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#834','2409','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#835','2096','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Gold Diamond Axe'],
['#836','3180','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Gold Diamond Axe'],
['#837','3049','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Green Classic Colt','Gold Diamond Axe'],
['#838','1774','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#839','1171','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#840','1344','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#841','681','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#842','3032','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#843','724','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#844','1170','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#845','2125','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#846','2196','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#847','1965','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#848','2498','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#849','1765','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#850','952','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#851','992','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#852','1251','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#853','1289','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#854','467','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#855','1719','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#856','3488','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#857','1580','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#858','1506','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#859','1303','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#860','256','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#861','1321','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#862','663','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#863','1091','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#864','3164','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#865','2207','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Classic Colt','Gold Diamond Axe'],
['#866','2288','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#867','3386','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#868','1235','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#869','3330','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#870','2186','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#871','304','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#872','2038','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#873','3475','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#874','1983','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#875','996','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#876','1664','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#877','1685','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Gold Diamond Axe'],
['#878','271','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#879','2812','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#880','3435','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#881','1862','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#882','3356','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#883','1320','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#884','3226','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#885','209','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#886','1856','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#887','2044','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#888','2231','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#889','2760','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#890','1222','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#891','1964','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#892','2848','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#893','2694','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#894','3041','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#895','3450','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#896','2297','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#897','2399','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#898','30','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#899','2982','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#900','1933','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#901','2272','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#902','1947','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#903','879','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#904','2975','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#905','2995','Trooper','Steampunk','Beach Glass','Gold Goggles','Gold Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#906','673','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#907','857','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#908','1696','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#909','1456','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#910','67','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#911','674','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#912','713','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#913','100','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#914','2959','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#915','2597','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#916','2762','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#917','3097','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#918','646','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#919','881','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#920','2171','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#921','591','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#922','1855','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#923','1471','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Purple Classic Colt','Pink Diamond Axe'],
['#924','1627','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Purple Classic Colt','Pink Diamond Axe'],
['#925','943','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Purple Classic Colt','Blue Diamond Axe'],
['#926','2325','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Purple Classic Colt','Blue Diamond Axe'],
['#927','2262','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#928','2532','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#929','3277','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#930','261','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#931','3190','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#932','562','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#933','875','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#934','37','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#935','889','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#936','3092','Trooper','Rock','Charcoal','Gold Mohawk','Purple Chain','Yellow Classic Colt','Green Diamond Axe'],
['#937','752','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#938','1185','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#939','720','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#940','783','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#941','1153','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Purple Classic Colt','Purple Diamond Axe'],
['#942','362','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#943','1079','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#944','1082','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#945','223','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#946','1536','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Purple Classic Colt','Pink Diamond Axe'],
['#947','1062','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#948','1975','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Purple Classic Colt','Blue Diamond Axe'],
['#949','3278','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Purple Classic Colt','Blue Diamond Axe'],
['#950','2599','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#951','922','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#952','108','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#953','1038','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#954','2629','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#955','2499','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#956','2218','Trooper','Steampunk','Lavender Grey','Gold Goggles','Gold Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#957','1973','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Yellow Classic Colt','Green Diamond Axe'],
['#958','3192','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Yellow Classic Colt','Green Diamond Axe'],
['#959','3038','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#960','14','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#961','1249','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#962','59','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#963','1151','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Purple Classic Colt','Purple Diamond Axe'],
['#964','2738','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Purple Classic Colt','Purple Diamond Axe'],
['#965','2811','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Pink Classic Colt','Pink Diamond Axe'],
['#966','1067','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Pink Classic Colt','Green Diamond Axe'],
['#967','1489','Trooper','Rock','Inky','Red Mohawk','Purple Chain','Gold Classic Colt','Blue Diamond Axe'],
['#968','2880','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#969','587','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#970','1613','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Gold Classic Colt','Green Diamond Axe'],
['#971','691','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Pink Classic Colt','Purple Diamond Axe'],
['#972','1292','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Pink Classic Colt','Purple Diamond Axe'],
['#973','1783','Trooper','Rock','Inky','Red Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#974','2452','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Pink Classic Colt','Blue Diamond Axe'],
['#975','3130','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#976','1714','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#977','2042','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#978','2558','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Green Classic Colt','Gold Diamond Axe'],
['#979','2305','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Gold Classic Colt','Pink Diamond Axe'],
['#980','503','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Pink Classic Colt','Green Diamond Axe'],
['#981','2568','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Pink Classic Colt','Green Diamond Axe'],
['#982','1736','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#983','129','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Gold Classic Colt','Blue Diamond Axe'],
['#984','62','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#985','2308','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#986','1649','Trooper','Rock','Inky','Gold Mohawk','White Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#987','325','Trooper','Rock','Plum','Gold Mohawk','White Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#988','1944','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Pink Classic Colt','Purple Diamond Axe'],
['#989','2864','Trooper','Rock','Inky','Gold Mohawk','White Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#990','3050','Trooper','Rock','Plum','Gold Mohawk','White Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#991','1236','Trooper','Rock','Inky','Gold Mohawk','White Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#992','1959','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Purple Classic Colt','Blue Diamond Axe'],
['#993','3246','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#994','2785','Trooper','Rock','Plum','Gold Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#995','3211','Trooper','Rock','Plum','Gold Mohawk','White Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#996','438','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Yellow Classic Colt','Green Diamond Axe'],
['#997','690','Trooper','Rock','Plum','Gold Mohawk','White Chain','Purple Classic Colt','Green Diamond Axe'],
['#998','342','Trooper','Rock','Inky','Gold Mohawk','White Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#999','2029','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1000','1661','Trooper','Rock','Plum','Gold Mohawk','White Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#1001','2841','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1002','433','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1003','1718','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1004','360','Trooper','Rock','Plum','Gold Mohawk','Purple Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#1005','3345','Trooper','Rock','Inky','Gold Mohawk','Purple Chain','Green Classic Colt','Purple Diamond Axe'],
['#1006','3132','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1007','3201','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1008','723','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#1009','471','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#1010','1505','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#1011','1173','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1012','509','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1013','3306','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1014','2640','Trooper','Rock','Charcoal','Gold Mohawk','Red Chain','Green Classic Colt','Pink Diamond Axe'],
['#1015','84','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Green Classic Colt','Pink Diamond Axe'],
['#1016','2667','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#1017','813','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1018','846','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1019','1614','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Green Classic Colt','Blue Diamond Axe'],
['#1020','638','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Green Classic Colt','Blue Diamond Axe'],
['#1021','3377','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#1022','1572','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1023','1413','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1024','3284','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#1025','2622','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#1026','1103','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1027','2697','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#1028','3110','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#1029','2362','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1030','352','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1031','1166','Trooper','Rock','Inky','Gold Mohawk','Red Chain','Green Classic Colt','Green Diamond Axe'],
['#1032','863','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1033','1562','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1034','2586','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1035','3265','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1036','249','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#1037','3214','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#1038','935','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#1039','1084','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1040','2572','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1041','96','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1042','1283','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1043','117','Trooper','Rock','Plum','Gold Mohawk','Red Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#1044','1388','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Pink Classic Colt','Pink Diamond Axe'],
['#1045','3384','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Pink Classic Colt','Pink Diamond Axe'],
['#1046','3286','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Gold Classic Colt','Turquoise Diamond Axe'],
['#1047','1154','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1048','2472','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1049','659','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1050','2303','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1051','2663','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1052','1887','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#1053','1857','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1054','2203','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1055','1144','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1056','3239','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1057','2713','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1058','2900','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1059','2583','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1060','1147','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1061','2198','Trooper','Rock','Plum','Red Mohawk','White Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1062','3224','Trooper','Rock','Plum','Red Mohawk','White Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1063','2435','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1064','842','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1065','1917','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1066','2699','Trooper','Retro','Mango Gradient','Gold Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1067','1939','Trooper','Rock','Plum','Gold Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#1068','3139','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1069','3034','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1070','1828','Trooper','Rock','Plum','Red Mohawk','White Chain','Gold Classic Colt','Blue Diamond Axe'],
['#1071','3067','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Gold Classic Colt','Blue Diamond Axe'],
['#1072','2181','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Gold Classic Colt','Blue Diamond Axe'],
['#1073','241','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1074','1023','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1075','2250','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1076','3138','Trooper','Rock','Inky','Gold Mohawk','White Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#1077','2014','Trooper','Rock','Inky','Green Mohawk','Red Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1078','1065','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1079','1267','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1080','2343','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1081','916','Trooper','Rock','Inky','Red Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#1082','2367','Trooper','Rock','Charcoal','Red Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#1083','2105','Trooper','Rock','Plum','Red Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#1084','386','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Gold Classic Colt','Blue Diamond Axe'],
['#1085','3380','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Pink Classic Colt','Purple Diamond Axe'],
['#1086','1772','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1087','2642','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1088','1439','Trooper','Rock','Inky','Red Mohawk','White Chain','Gold Classic Colt','Turquoise Diamond Axe'],
['#1089','2200','Trooper','Rock','Inky','Green Mohawk','Red Chain','Gold Classic Colt','Green Diamond Axe'],
['#1090','1294','Trooper','Rock','Inky','Red Mohawk','White Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1091','1890','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#1092','2301','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1093','53','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1094','2174','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1095','651','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1096','2206','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1097','2081','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1098','627','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1099','2137','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1100','1446','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1101','2098','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1102','3261','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1103','76','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1104','2797','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1105','2287','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1106','2702','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1107','3063','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1108','3062','Trooper','Rock','Inky','Green Mohawk','Red Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1109','2110','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1110','3379','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Gold Classic Colt','Blue Diamond Axe'],
['#1111','385','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1112','780','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1113','155','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1114','953','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1115','2971','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1116','1374','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1117','1300','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1118','2817','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#1119','496','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#1120','2156','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1121','3283','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Gold Classic Colt','Blue Diamond Axe'],
['#1122','1334','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Gold Classic Colt','Blue Diamond Axe'],
['#1123','243','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1124','2881','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#1125','3209','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#1126','446','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1127','3086','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1128','1721','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#1129','1398','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#1130','1121','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1131','2554','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1132','2187','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1133','3425','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1134','2358','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1135','710','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#1136','3260','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#1137','337','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#1138','244','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1139','106','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1140','1234','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1141','2560','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Gold Classic Colt','Green Diamond Axe'],
['#1142','135','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1143','2447','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1144','3252','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#1145','1043','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#1146','1643','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1147','2339','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1148','3217','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1149','190','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#1150','2674','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#1151','276','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#1152','506','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#1153','1885','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#1154','733','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1155','721','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1156','2962','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1157','3405','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1158','1287','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1159','329','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#1160','3449','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#1161','991','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1162','1809','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#1163','1037','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1164','1900','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1165','3058','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1166','2373','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1167','3002','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1168','3121','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1169','486','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1170','1780','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1171','1799','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1172','1920','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1173','1100','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1174','2744','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1175','3392','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1176','306','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1177','940','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1178','287','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Purple Classic Colt','Green Diamond Axe'],
['#1179','3460','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#1180','286','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#1181','2123','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1182','2448','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1183','1535','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1184','2057','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#1185','1172','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1186','903','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1187','1709','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1188','394','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1189','3404','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1190','12','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1191','561','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1192','2241','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1193','2134','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1194','65','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#1195','894','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1196','1061','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#1197','2748','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1198','137','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1199','2122','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1200','3272','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Gold Classic Colt','Purple Diamond Axe'],
['#1201','1176','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1202','251','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1203','2229','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#1204','1553','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1205','2327','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1206','469','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1207','2856','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Green Classic Colt','Green Diamond Axe'],
['#1208','2419','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#1209','2970','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#1210','1142','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#1211','2952','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Gold Classic Colt','Pink Diamond Axe'],
['#1212','547','Trooper','Rock','Plum','Gold Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#1213','21','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1214','127','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1215','793','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1216','826','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1217','2999','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1218','796','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1219','2666','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1220','2969','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1221','1411','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1222','1527','Trooper','Rock','Inky','Gold Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#1223','1490','Trooper','Rock','Charcoal','Gold Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#1224','977','Trooper','Rock','Plum','Gold Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#1225','1669','Trooper','Rock','Plum','Gold Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#1226','414','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#1227','2277','Trooper','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1228','2005','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1229','2646','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1230','3231','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Gold Classic Colt','Green Diamond Axe'],
['#1231','2406','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1232','798','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Gold Classic Colt','Turquoise Diamond Axe'],
['#1233','1204','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1234','550','Trooper','Rock','Plum','Green Mohawk','White Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1235','900','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1236','3075','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1237','817','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1238','2753','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1239','676','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1240','3382','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1241','3247','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1242','843','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1243','2179','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1244','3095','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1245','955','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1246','497','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#1247','1654','Trooper','Rock','Plum','Green Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#1248','448','Trooper','Retro','Lime Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1249','2625','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1250','2939','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Gold Classic Colt','Green Diamond Axe'],
['#1251','1625','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1252','984','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1253','2869','Trooper','Rock','Plum','Green Mohawk','White Chain','Gold Classic Colt','Turquoise Diamond Axe'],
['#1254','3381','Trooper','Rock','Plum','Green Mohawk','White Chain','Gold Classic Colt','Turquoise Diamond Axe'],
['#1255','206','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#1256','3433','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1257','766','Trooper','Retro','Lime Gradient','Gold Headphones','White Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1258','310','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1259','1482','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1260','2828','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1261','3235','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1262','2009','Trooper','Rock','Inky','Red Mohawk','Purple Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1263','860','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1264','2151','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1265','132','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Pink Classic Colt','Green Diamond Axe'],
['#1266','3128','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1267','1779','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1268','932','Trooper','Retro','Lavender Gradient','Gold Headphones','White Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1269','3401','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Green Diamond Axe'],
['#1270','3135','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1271','820','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1272','1359','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1273','3369','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1274','594','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#1275','285','Trooper','Rock','Plum','Purple Mohawk','White Chain','Gold Classic Colt','Pink Diamond Axe'],
['#1276','2220','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#1277','2815','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#1278','1929','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1279','423','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1280','3458','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1281','2192','Trooper','Retro','Lavender Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1282','101','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#1283','2361','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1284','3428','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1285','3031','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Pink Classic Colt','Purple Diamond Axe'],
['#1286','75','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1287','2139','Trooper','Retro','Lime Gradient','Gold Headphones','Purple Walkman','Green Classic Colt','Purple Diamond Axe'],
['#1288','1256','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#1289','1665','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#1290','2164','Trooper','Retro','Mango Gradient','Gold Headphones','White Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1291','1989','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#1292','2138','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#1293','195','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#1294','2507','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#1295','618','Trooper','Rock','Inky','Purple Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#1296','1274','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Gold Classic Colt','Green Diamond Axe'],
['#1297','1871','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#1298','806','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#1299','3290','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#1300','1138','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1301','2580','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1302','2542','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#1303','2874','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#1304','1953','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#1305','456','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#1306','46','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#1307','2320','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1308','412','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#1309','1382','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1310','2534','Trooper','Hippie','Blush','Gold Head Garland','Blue Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1311','635','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1312','3464','Trooper','Rock','Plum','Purple Mohawk','White Chain','Gold Classic Colt','Purple Diamond Axe'],
['#1313','1141','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1314','3194','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#1315','1710','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#1316','118','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1317','1711','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1318','146','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#1319','103','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1320','1109','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1321','379','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1322','2675','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Purple Classic Colt','Pink Diamond Axe'],
['#1323','3390','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Purple Classic Colt','Pink Diamond Axe'],
['#1324','705','Trooper','Rock','Inky','Red Mohawk','Red Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1325','144','Trooper','Rock','Plum','Red Mohawk','Red Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1326','1095','Trooper','Rock','Plum','Red Mohawk','Red Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1327','321','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1328','2058','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#1329','229','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Purple Classic Colt','Blue Diamond Axe'],
['#1330','1175','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Purple Classic Colt','Blue Diamond Axe'],
['#1331','2161','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1332','1032','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1333','1999','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#1334','344','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1335','1605','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1336','1658','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1337','2652','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#1338','731','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1339','203','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1340','732','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1341','2635','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1342','2423','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1343','2061','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#1344','3437','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1345','2698','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1346','2990','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1347','1813','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1348','1948','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1349','1952','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1350','1263','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#1351','1556','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1352','2578','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1353','2533','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1354','946','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#1355','272','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#1356','3398','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#1357','2754','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#1358','1618','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#1359','23','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#1360','1376','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#1361','2932','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1362','3326','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1363','776','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1364','2404','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1365','1181','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1366','262','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1367','370','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1368','3312','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1369','43','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1370','2913','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1371','694','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Purple Classic Colt','Purple Diamond Axe'],
['#1372','2109','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#1373','2260','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1374','465','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1375','2630','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1376','126','Trooper','Hippie','Lilac','Gold Head Garland','Pink Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1377','2909','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#1378','191','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#1379','960','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#1380','226','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1381','2135','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1382','936','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Purple Classic Colt','Pink Diamond Axe'],
['#1383','812','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Purple Classic Colt','Pink Diamond Axe'],
['#1384','1617','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Purple Classic Colt','Pink Diamond Axe'],
['#1385','2602','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1386','730','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1387','3114','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1388','621','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#1389','2614','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#1390','3311','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#1391','2986','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1392','3177','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1393','2015','Trooper','Retro','Mango Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Green Diamond Axe'],
['#1394','1367','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1395','1686','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1396','24','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1397','77','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#1398','1387','Trooper','Hippie','Glass Green','Gold Head Garland','Pink Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#1399','1426','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1400','1339','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Green Classic Colt','Blue Diamond Axe'],
['#1401','2692','Trooper','Cyberpunk','Marigold','Gold Mask','Blue Earphones','Green Classic Colt','Blue Diamond Axe'],
['#1402','3359','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1403','2189','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#1404','2323','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#1405','1620','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1406','2809','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#1407','2845','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#1408','2607','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#1409','176','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1410','2295','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1411','1286','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1412','1253','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Purple Classic Colt','Green Diamond Axe'],
['#1413','1199','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1414','3044','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1415','3043','Trooper','Hippie','Blush','Gold Head Garland','Pink Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#1416','2865','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1417','1072','Trooper','Retro','Lavender Gradient','Gold Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#1418','260','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1419','1466','Trooper','Cyberpunk','Maui Blue','Gold Mask','Blue Earphones','Green Classic Colt','Green Diamond Axe'],
['#1420','2412','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1421','2794','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1422','2665','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#1423','1730','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1424','3080','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1425','1280','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#1426','2730','Trooper','Rock','Charcoal','Red Mohawk','Gold Chain','Green Classic Colt','Green Diamond Axe'],
['#1427','1904','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#1428','70','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#1429','2831','Trooper','Rock','Inky','Purple Mohawk','Gold Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#1430','1493','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#1431','517','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1432','178','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1433','2577','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1434','756','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1435','1279','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1436','3439','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1437','1663','Trooper','Cyberpunk','Monster Green','Gold Mask','Blue Earphones','Green Classic Colt','Purple Diamond Axe'],
['#1438','312','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1439','511','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1440','1829','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1441','2500','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1442','784','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#1443','1717','Trooper','Rock','Inky','Red Mohawk','Gold Chain','Green Classic Colt','Purple Diamond Axe'],
['#1444','970','Trooper','Rock','Plum','Red Mohawk','Gold Chain','Green Classic Colt','Purple Diamond Axe'],
['#1445','2450','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1446','1328','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Gold Classic Colt','Green Diamond Axe'],
['#1447','2350','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Gold Classic Colt','Green Diamond Axe'],
['#1448','3322','Trooper','Rock','Inky','Red Mohawk','Red Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1449','559','Trooper','Rock','Plum','Red Mohawk','Red Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1450','3178','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1451','1299','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1452','1760','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1453','543','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1454','3004','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1455','1926','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#1456','556','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1457','2427','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1458','1430','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1459','3215','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#1460','2745','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1461','3370','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#1462','2945','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1463','2693','Trooper','Hippie','Glass Green','Gold Head Garland','Blue Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#1464','825','Trooper','Hippie','Lilac','Gold Head Garland','Blue Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#1465','328','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1466','537','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1467','2803','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1468','2310','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1469','1925','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1470','484','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1471','2279','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#1472','1438','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1473','2783','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1474','2458','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1475','3181','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1476','1691','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1477','1498','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1478','3362','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1479','1798','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1480','592','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1481','1366','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1482','1424','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1483','1336','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#1484','1197','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1485','2780','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1486','2863','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1487','3089','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1488','586','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1489','3204','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1490','670','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1491','816','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#1492','2827','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#1493','192','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1494','993','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#1495','2605','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#1496','2194','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#1497','910','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#1498','801','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1499','2008','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1500','3441','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1501','174','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1502','2457','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1503','2353','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#1504','3413','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1505','2180','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#1506','2763','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1507','1442','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1508','845','Trooper','Rock','Plum','Red Mohawk','White Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#1509','2539','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#1510','504','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1511','2030','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1512','2920','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#1513','199','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1514','2400','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1515','2593','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1516','868','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1517','1770','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1518','1822','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1519','2598','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1520','3036','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1521','3479','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1522','578','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1523','1434','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1524','2679','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1525','1220','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1526','1298','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1527','3399','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1528','449','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1529','228','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1530','3418','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1531','2082','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Pink Classic Colt','Pink Diamond Axe'],
['#1532','878','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1533','2176','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#1534','1436','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1535','3302','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#1536','331','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1537','2290','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1538','2108','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#1539','2618','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#1540','2662','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#1541','3376','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#1542','2819','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1543','3365','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#1544','898','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1545','1609','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1546','2552','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1547','1674','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#1548','3145','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#1549','464','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1550','785','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1551','2832','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1552','2245','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1553','2960','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1554','1164','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1555','2333','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#1556','524','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1557','1268','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1558','678','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1559','3257','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1560','2743','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#1561','47','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1562','1163','Trooper','Rock','Inky','Green Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1563','3270','Trooper','Rock','Inky','Green Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1564','2527','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1565','906','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1566','805','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1567','2974','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Green Classic Colt','Gold Diamond Axe'],
['#1568','389','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1569','1967','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#1570','149','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#1571','617','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#1572','677','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#1573','974','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#1574','2788','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1575','2040','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1576','2034','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#1577','349','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#1578','2259','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#1579','3438','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Pink Classic Colt','Purple Diamond Axe'],
['#1580','280','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#1581','318','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#1582','2555','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#1583','2369','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1584','345','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1585','2691','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1586','597','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1587','2402','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1588','620','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#1589','3198','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#1590','1021','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#1591','3117','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#1592','2142','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1593','2482','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1594','989','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#1595','3144','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1596','1076','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1597','1688','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#1598','91','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#1599','905','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#1600','2365','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#1601','2935','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#1602','3385','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#1603','363','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1604','1461','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1605','3400','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1606','493','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1607','2438','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1608','417','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#1609','1623','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1610','1128','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1611','2603','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1612','954','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1613','1655','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Gold Classic Colt','Pink Diamond Axe'],
['#1614','2608','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#1615','1427','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#1616','2492','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1617','1841','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1618','2475','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1619','1877','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1620','534','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1621','2953','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1622','447','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1623','999','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1624','2530','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1625','2617','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1626','722','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1627','2998','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1628','2249','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1629','1706','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1630','2099','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1631','1896','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#1632','2007','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1633','1075','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1634','73','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Gold Classic Colt','Green Diamond Axe'],
['#1635','2470','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Gold Classic Colt','Green Diamond Axe'],
['#1636','2687','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Gold Classic Colt','Green Diamond Axe'],
['#1637','2216','Trooper','Rock','Inky','Green Mohawk','Gold Chain','Green Classic Colt','Pink Diamond Axe'],
['#1638','2464','Trooper','Rock','Inky','Red Mohawk','Red Chain','Green Classic Colt','Gold Diamond Axe'],
['#1639','1244','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#1640','2431','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1641','615','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1642','1632','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#1643','380','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#1644','1133','Trooper','Rock','Plum','Green Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#1645','1074','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1646','664','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1647','2670','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1648','415','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1649','2421','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1650','3016','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1651','1097','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Yellow Classic Colt','Gold Diamond Axe'],
['#1652','421','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Green Classic Colt','Green Diamond Axe'],
['#1653','17','Trooper','Cyberpunk','Monster Green','Gold Mask','Pink Earphones','Green Classic Colt','Green Diamond Axe'],
['#1654','58','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1655','1085','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1656','1340','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1657','1469','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1658','1006','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1659','1668','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#1660','3463','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#1661','1544','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1662','2252','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1663','3107','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1664','2773','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1665','63','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1666','2704','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1667','2332','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1668','1836','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1669','1343','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#1670','90','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1671','1543','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Green Diamond Axe'],
['#1672','89','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1673','1909','Trooper','Cyberpunk','Maui Blue','Gold Mask','Pink Earphones','Green Classic Colt','Purple Diamond Axe'],
['#1674','626','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Green Classic Colt','Purple Diamond Axe'],
['#1675','997','Trooper','Cyberpunk','Marigold','Gold Mask','Pink Earphones','Green Classic Colt','Purple Diamond Axe'],
['#1676','1945','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#1677','3040','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1678','298','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#1679','1282','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#1680','2213','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#1681','810','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#1682','2895','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1683','3156','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1684','1773','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#1685','2535','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Pink Diamond Axe'],
['#1686','382','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#1687','2561','Trooper','Hippie','Lilac','Gold Head Garland','Green Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#1688','1110','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1689','2121','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1690','3430','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1691','505','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#1692','2571','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1693','2466','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#1694','836','Trooper','Rock','Charcoal','Green Mohawk','Gold Chain','Green Classic Colt','Purple Diamond Axe'],
['#1695','1373','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1696','2230','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1697','2822','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Blue Diamond Axe'],
['#1698','1031','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1699','1961','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1700','2257','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1701','2356','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#1702','2619','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#1703','1383','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#1704','2800','Trooper','Hippie','Glass Green','Gold Head Garland','Green Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#1705','3054','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Green Classic Colt','Pink Diamond Axe'],
['#1706','3274','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Green Classic Colt','Pink Diamond Axe'],
['#1707','2721','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Turquoise Classic Colt','Gold Diamond Axe'],
['#1708','2162','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#1709','368','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1710','1720','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1711','2902','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1712','1033','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#1713','1636','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#1714','1219','Trooper','Hippie','Blush','Gold Head Garland','Green Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#1715','187','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Green Classic Colt','Blue Diamond Axe'],
['#1716','2801','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1717','3008','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Green Diamond Axe'],
['#1718','2059','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1719','20','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1720','2888','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#1721','1866','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#1722','2686','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1723','94','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1724','799','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1725','2380','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#1726','757','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1727','3374','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Turquoise Diamond Axe'],
['#1728','2847','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1729','3221','Trooper','Rock','Charcoal','Purple Mohawk','Gold Chain','Green Classic Colt','Green Diamond Axe'],
['#1730','2441','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Green Classic Colt','Green Diamond Axe'],
['#1731','532','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#1732','3417','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#1733','2067','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Gold Diamond Axe'],
['#1734','2987','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Gold Classic Colt','Purple Diamond Axe'],
['#1735','2004','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1736','2820','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#1737','2166','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#1738','1305','Trooper','Rock','Inky','Green Mohawk','White Chain','Turquoise Classic Colt','Gold Diamond Axe'],
['#1739','3157','Trooper','Rock','Plum','Purple Mohawk','Gold Chain','Green Classic Colt','Purple Diamond Axe'],
['#1740','3241','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1741','2170','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1742','1577','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1743','1129','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1744','1960','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#1745','2570','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#1746','3148','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1747','2093','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1748','2540','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1749','564','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#1750','2032','Trooper','Rock','Inky','Green Mohawk','White Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1751','775','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1752','1551','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1753','3033','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1754','679','Trooper','Rock','Plum','Green Mohawk','White Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1755','2474','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1756','2961','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1757','2379','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1758','177','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#1759','1697','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1760','197','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#1761','2749','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1762','2870','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1763','1184','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1764','1882','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#1765','150','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#1766','1400','Trooper','Retro','Lime Gradient','White Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1767','567','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1768','686','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1769','1539','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1770','876','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#1771','2158','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#1772','408','Trooper','Cyberpunk','Marigold','Gold Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#1773','3268','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#1774','2480','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1775','121','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Green Diamond Axe'],
['#1776','157','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1777','2077','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1778','1771','Trooper','Cyberpunk','Monster Green','Gold Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#1779','649','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1780','54','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1781','546','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1782','2795','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Green Classic Colt','Green Diamond Axe'],
['#1783','309','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Green Classic Colt','Green Diamond Axe'],
['#1784','1125','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1785','2626','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#1786','313','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1787','2357','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#1788','853','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1789','1165','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1790','1659','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1791','2989','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1792','1026','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#1793','1210','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1794','2926','Trooper','Hippie','Glass Green','Blue Head Garland','Gold Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1795','907','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#1796','2011','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#1797','896','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1798','925','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#1799','3366','Trooper','Rock','Inky','Purple Mohawk','White Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1800','926','Trooper','Rock','Plum','Purple Mohawk','White Chain','Yellow Classic Colt','Gold Diamond Axe'],
['#1801','1152','Trooper','Rock','Plum','Purple Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1802','2314','Trooper','Rock','Plum','Purple Mohawk','White Chain','Purple Classic Colt','Gold Diamond Axe'],
['#1803','672','Trooper','Retro','Lavender Gradient','White Headphones','Gold Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1804','2336','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1805','3218','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1806','1391','Trooper','Cyberpunk','Maui Blue','Gold Mask','Green Earphones','Green Classic Colt','Purple Diamond Axe'],
['#1807','3443','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1808','3006','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1809','840','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1810','2661','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1811','3305','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1812','1763','Trooper','Retro','Mango Gradient','White Headphones','Gold Walkman','Green Classic Colt','Purple Diamond Axe'],
['#1813','142','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#1814','3448','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#1815','2202','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#1816','770','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#1817','1750','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#1818','1096','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#1819','3317','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#1820','294','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1821','2208','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1822','1557','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1823','25','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1824','3403','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1825','668','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#1826','188','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1827','824','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#1828','2891','Trooper','Hippie','Blush','Blue Head Garland','Gold Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#1829','1980','Trooper','Hippie','Lilac','Blue Head Garland','Gold Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#1830','2132','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#1831','225','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1832','1638','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Gold Diamond Axe'],
['#1833','501','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1834','3432','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#1835','376','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1836','1542','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1837','1550','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1838','1850','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#1839','2349','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#1840','1304','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#1841','365','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1842','1585','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Purple Classic Colt','Green Diamond Axe'],
['#1843','1884','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Purple Classic Colt','Green Diamond Axe'],
['#1844','2157','Trooper','Retro','Mango Gradient','Blue Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1845','1646','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1846','22','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1847','897','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1848','435','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1849','1399','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1850','2117','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1851','3482','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1852','683','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1853','1687','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1854','2119','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Gold Classic Colt','Pink Diamond Axe'],
['#1855','2016','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1856','2840','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1857','1221','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#1858','2673','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#1859','2899','Trooper','Steampunk','Beach Glass','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#1860','755','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#1861','1806','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#1862','1429','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1863','2978','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1864','2678','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Gold Classic Colt','Blue Diamond Axe'],
['#1865','3451','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1866','1739','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1867','3059','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#1868','2193','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1869','2393','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#1870','585','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#1871','1245','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#1872','1508','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#1873','2422','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#1874','2481','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#1875','1375','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#1876','1194','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1877','355','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1878','1571','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#1879','1115','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1880','1369','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1881','2437','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#1882','3195','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1883','1578','Trooper','Retro','Lime Gradient','Blue Headphones','Gold Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1884','1726','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1885','92','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1886','1053','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#1887','2233','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1888','1968','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Gold Classic Colt','Turquoise Diamond Axe'],
['#1889','3426','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1890','3100','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1891','2524','Trooper','Steampunk','Lavender Grey','Brown Goggles','Gold Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#1892','2072','Trooper','Retro','Lavender Gradient','Blue Headphones','Gold Walkman','Green Classic Colt','Purple Diamond Axe'],
['#1893','885','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#1894','1013','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1895','338','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#1896','1018','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#1897','1502','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1898','2280','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1899','3452','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Gold Classic Colt','Purple Diamond Axe'],
['#1900','1034','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1901','2757','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#1902','872','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1903','3357','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1904','1243','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1905','141','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1906','2103','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Gold Classic Colt','Blue Diamond Axe'],
['#1907','665','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#1908','3319','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#1909','2849','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#1910','2313','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#1911','1652','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#1912','1754','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#1913','341','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1914','1865','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Pink Diamond Axe'],
['#1915','753','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Classic Colt','Green Diamond Axe'],
['#1916','115','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#1917','750','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#1918','1094','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#1919','611','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Blue Diamond Axe'],
['#1920','1127','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#1921','1852','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1922','110','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1923','1893','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1924','2616','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#1925','536','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Classic Colt','Turquoise Diamond Axe'],
['#1926','716','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#1927','598','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1928','1214','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#1929','145','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Pink Classic Colt','Gold Diamond Axe'],
['#1930','2445','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#1931','3127','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#1932','1318','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#1933','1437','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#1934','2485','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1935','2965','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Gold Classic Colt','Purple Diamond Axe'],
['#1936','758','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#1937','789','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#1938','1227','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#1939','2436','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#1940','2853','Trooper','Hippie','Blush','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#1941','3072','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#1942','1712','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#1943','2265','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#1944','2993','Trooper','Retro','Lime Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1945','2468','Trooper','Retro','Lavender Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#1946','1974','Trooper','Hippie','Glass Green','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1947','1319','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#1948','507','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1949','2050','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1950','2092','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Gold Diamond Axe'],
['#1951','2700','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#1952','193','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#1953','571','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#1954','1742','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#1955','2826','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#1956','2236','Trooper','Retro','Mango Gradient','Green Headphones','Gold Walkman','Green Classic Colt','Purple Diamond Axe'],
['#1957','490','Trooper','Hippie','Blush','Yellow Head Garland','Gold Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#1958','2889','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1959','2946','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#1960','3233','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#1961','515','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1962','1970','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1963','2984','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1964','3066','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1965','1302','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#1966','1758','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#1967','1030','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1968','1611','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1969','1069','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1970','2722','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1971','1269','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#1972','2968','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#1973','156','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Green Classic Colt','Gold Diamond Axe'],
['#1974','2160','Trooper','Hippie','Lilac','Pink Head Garland','Gold Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#1975','662','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#1976','1895','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1977','57','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#1978','3318','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#1979','2382','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#1980','3227','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#1981','3163','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#1982','1574','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1983','442','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1984','2191','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1985','2512','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#1986','1597','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#1987','2269','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#1988','1707','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#1989','2493','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#1990','680','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Green Classic Colt','Gold Diamond Axe'],
['#1991','2184','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Green Classic Colt','Gold Diamond Axe'],
['#1992','3328','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#1993','948','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#1994','301','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#1995','1985','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#1996','1499','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#1997','1594','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#1998','1891','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#1999','3263','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2000','1849','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2001','2537','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2002','1912','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2003','2839','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#2004','1600','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2005','1000','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2006','3122','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#2007','1277','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2008','634','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2009','2408','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2010','2916','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2011','2766','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#2012','1276','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#2013','1246','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2014','416','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2015','1052','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2016','2443','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2017','1942','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#2018','371','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2019','2653','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#2020','3124','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2021','257','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#2022','3393','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2023','3088','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2024','3269','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2025','2416','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#2026','2083','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#2027','1622','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#2028','2567','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2029','2529','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2030','3027','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2031','481','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2032','3468','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#2033','2065','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#2034','643','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#2035','2418','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#2036','1731','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#2037','1990','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#2038','1070','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#2039','1579','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2040','2758','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2041','3000','Trooper','Rock','Inky','Purple Mohawk','White Chain','Green Classic Colt','Gold Diamond Axe'],
['#2042','2247','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2043','2777','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2044','3255','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#2045','3474','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#2046','1390','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#2047','2463','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2048','1102','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2049','2588','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2050','1521','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2051','487','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2052','2149','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2053','1581','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2054','2102','Trooper','Hippie','Lilac','Yellow Head Garland','Gold Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#2055','3146','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#2056','1599','Trooper','Cyberpunk','Maui Blue','Blue Mask','Gold Earphones','Green Classic Colt','Blue Diamond Axe'],
['#2057','945','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Blue Diamond Axe'],
['#2058','1203','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Blue Diamond Axe'],
['#2059','1389','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#2060','1423','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2061','2066','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#2062','1178','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#2063','2239','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#2064','3071','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Purple Classic Colt','Gold Diamond Axe'],
['#2065','904','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2066','1992','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#2067','1295','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#2068','1120','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2069','855','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2070','332','Trooper','Hippie','Glass Green','Yellow Head Garland','Gold Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#2071','2991','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Green Classic Colt','Green Diamond Axe'],
['#2072','2852','Trooper','Cyberpunk','Marigold','Green Mask','Gold Earphones','Green Classic Colt','Green Diamond Axe'],
['#2073','3060','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Green Diamond Axe'],
['#2074','1081','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2075','1590','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2076','2963','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2077','351','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#2078','1880','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#2079','2398','Trooper','Cyberpunk','Marigold','Blue Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#2080','986','Trooper','Cyberpunk','Monster Green','Green Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#2081','2988','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#2082','1364','Trooper','Cyberpunk','Maui Blue','Green Mask','Gold Earphones','Green Classic Colt','Purple Diamond Axe'],
['#2083','2052','Trooper','Cyberpunk','Monster Green','Blue Mask','Gold Earphones','Green Classic Colt','Purple Diamond Axe'],
['#2084','1258','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2085','1168','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2086','1448','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#2087','856','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#2088','3161','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#2089','2131','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2090','2942','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2091','911','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2092','947','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2093','1449','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#2094','2565','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#2095','972','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Yellow Classic Colt','Gold Diamond Axe'],
['#2096','3446','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Gold Diamond Axe'],
['#2097','2628','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2098','2631','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#2099','401','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2100','2359','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2101','1794','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#2102','3155','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#2103','2255','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#2104','119','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2105','1207','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2106','1639','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2107','2018','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#2108','2294','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2109','3113','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2110','829','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2111','227','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2112','818','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#2113','282','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#2114','136','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#2115','162','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2116','743','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2117','2806','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2118','913','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#2119','3208','Trooper','Steampunk','Lavender Grey','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#2120','3237','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2121','2843','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2122','3065','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#2123','2168','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Pink Classic Colt','Green Diamond Axe'],
['#2124','485','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#2125','2930','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2126','2411','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2127','2155','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2128','1451','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2129','3483','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2130','429','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2131','1261','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2132','2175','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2133','2199','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2134','2976','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2135','2106','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2136','967','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2137','2344','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2138','68','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2139','3324','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2140','290','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#2141','1531','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#2142','3191','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#2143','1418','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#2144','3068','Trooper','Steampunk','Beach Glass','Gold Goggles','Brown Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#2145','367','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#2146','2651','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#2147','1177','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2148','1474','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2149','1547','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2150','1788','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2151','1923','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2152','1254','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2153','2330','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2154','3234','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2155','1009','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2156','1217','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2157','3057','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2158','1826','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2159','1889','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2160','1360','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#2161','839','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2162','2413','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2163','3355','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#2164','2462','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2165','460','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#2166','768','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#2167','2632','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Gold Diamond Axe'],
['#2168','2886','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2169','2352','Trooper','Steampunk','Beach Glass','Grey Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#2170','120','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2171','1701','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#2172','315','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2173','3186','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2174','2488','Trooper','Steampunk','Lavender Grey','Grey Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2175','3383','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2176','3415','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2177','163','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2178','1558','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2179','1435','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2180','1187','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#2181','2195','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Turquoise Classic Colt','Gold Diamond Axe'],
['#2182','1452','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#2183','3009','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2184','1158','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2185','1010','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#2186','675','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2187','3026','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2188','1396','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2189','3172','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2190','2430','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2191','179','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2192','837','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Gold Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#2193','2159','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Gold Earphones','Green Classic Colt','Blue Diamond Axe'],
['#2194','3295','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#2195','45','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#2196','2483','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Yellow Classic Colt','Gold Diamond Axe'],
['#2197','268','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#2198','1844','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#2199','1928','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2200','2010','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2201','2585','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2202','128','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Gold Diamond Axe'],
['#2203','2204','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2204','1854','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2205','2013','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2206','479','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2207','3010','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2208','1946','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2209','1025','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2210','800','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Turquoise Classic Colt','Gold Diamond Axe'],
['#2211','1692','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2212','3045','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#2213','1716','Trooper','Steampunk','Lavender Grey','Black Goggles','Gold Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#2214','450','Trooper','Steampunk','Beach Glass','Black Goggles','Gold Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#2215','637','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#2216','2212','Trooper','Cyberpunk','Marigold','Yellow Mask','Gold Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#2217','3018','Trooper','Cyberpunk','Monster Green','Yellow Mask','Gold Earphones','Green Classic Colt','Purple Diamond Axe'],
['#2218','483','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2219','2342','Trooper','Steampunk','Burnt Sienna','Black Goggles','Gold Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#2220','1601','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2221','966','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2222','1462','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Yellow Classic Colt','Gold Diamond Axe'],
['#2223','3334','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2224','2657','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Gold Diamond Axe'],
['#2225','3485','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2226','3011','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2227','2141','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#2228','3149','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#2229','3136','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Pink Classic Colt','Gold Diamond Axe'],
['#2230','2312','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2231','3320','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2232','3091','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2233','1827','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2234','2911','Trooper','Steampunk','Beach Glass','Gold Goggles','Yellow Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#2235','2842','Trooper','Steampunk','Burnt Sienna','Gold Goggles','Black Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#2236','1307','Trooper','Steampunk','Lavender Grey','Gold Goggles','Yellow Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#2237','1183','Trooper','Steampunk','Beach Glass','Gold Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#2238','2610','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2239','3281','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Pink Classic Colt','Gold Diamond Axe'],
['#2240','95','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#2241','1740','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#2242','1995','Trooper','Steampunk','Lavender Grey','Gold Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#2243','1976','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2244','2001','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Green Classic Colt','Gold Diamond Axe'],
['#2245','1755','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2246','2473','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2247','3368','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2248','2725','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2249','168','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2250','1106','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2251','69','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Green Classic Colt','Gold Diamond Axe'],
['#2252','133','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2253','712','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2254','2465','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2255','1596','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Purple Classic Colt','Green Diamond Axe'],
['#2256','2611','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Purple Classic Colt','Green Diamond Axe'],
['#2257','539','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2258','1478','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Purple Classic Colt','Green Diamond Axe'],
['#2259','326','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2260','1741','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2261','1782','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2262','870','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2263','1680','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2264','1260','Trooper','Rock','Inky','Red Mohawk','Red Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2265','3151','Trooper','Rock','Inky','Red Mohawk','Red Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2266','640','Trooper','Rock','Plum','Red Mohawk','Red Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2267','2636','Trooper','Rock','Plum','Red Mohawk','Red Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2268','1167','Trooper','Rock','Inky','Red Mohawk','Red Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2269','2739','Trooper','Rock','Inky','Red Mohawk','Red Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2270','183','Trooper','Rock','Plum','Red Mohawk','Red Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2271','246','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2272','422','Trooper','Rock','Plum','Red Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2273','1476','Trooper','Rock','Plum','Red Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2274','629','Trooper','Rock','Plum','Red Mohawk','Red Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2275','242','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2276','2178','Trooper','Rock','Plum','Red Mohawk','Red Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2277','3051','Trooper','Rock','Inky','Red Mohawk','Red Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2278','164','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2279','347','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2280','215','Trooper','Rock','Plum','Red Mohawk','Red Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2281','890','Trooper','Rock','Plum','Red Mohawk','Red Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2282','2490','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2283','366','Trooper','Rock','Inky','Red Mohawk','Purple Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2284','1704','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2285','1843','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2286','3175','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2287','1379','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2288','2862','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2289','937','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2290','2867','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2291','508','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2292','715','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2293','1296','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2294','3073','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2295','140','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2296','2604','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2297','1699','Trooper','Rock','Inky','Red Mohawk','Purple Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2298','735','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2299','790','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2300','2150','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2301','2672','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2302','3228','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2303','2214','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2304','583','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2305','2737','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#2306','802','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2307','2711','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2308','116','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2309','3168','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2310','307','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2311','1099','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2312','1943','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2313','245','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2314','1899','Trooper','Rock','Inky','Red Mohawk','Red Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2315','2124','Trooper','Rock','Inky','Red Mohawk','Red Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2316','3307','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2317','212','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2318','530','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2319','2388','Trooper','Retro','Mango Gradient','White Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2320','734','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2321','2114','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2322','3207','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2323','3222','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2324','1486','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2325','1529','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2326','647','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2327','1589','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2328','1936','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2329','2876','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Pink Classic Colt','Green Diamond Axe'],
['#2330','1015','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2331','2118','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2332','1897','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2333','3301','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2334','1481','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2335','1732','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2336','356','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2337','963','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2338','1689','Trooper','Rock','Charcoal','Red Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2339','531','Trooper','Rock','Plum','Red Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2340','693','Trooper','Rock','Plum','Red Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2341','861','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2342','3427','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2343','2948','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2344','830','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2345','909','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2346','700','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Purple Classic Colt','Green Diamond Axe'],
['#2347','2901','Trooper','Rock','Inky','Red Mohawk','White Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2348','912','Trooper','Rock','Plum','Red Mohawk','White Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2349','3173','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2350','61','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2351','1757','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2352','1137','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2353','3352','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2354','2887','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2355','1690','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2356','3273','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2357','2222','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2358','39','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2359','2111','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2360','1561','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2361','1472','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2362','1513','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2363','1119','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2364','779','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2365','10','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2366','222','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2367','2143','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2368','400','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#2369','1869','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2370','3303','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2371','725','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2372','1080','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2373','1190','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2374','809','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2375','764','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2376','1162','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2377','2546','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2378','2624','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2379','2719','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2380','1591','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2381','2793','Trooper','Rock','Inky','Red Mohawk','White Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2382','2601','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#2383','1007','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2384','3094','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2385','3313','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2386','357','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2387','1193','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2388','1473','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2389','74','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2390','2033','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#2391','1628','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2392','3236','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2393','2101','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2394','1363','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2395','3444','Trooper','Rock','Plum','Green Mohawk','Red Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2396','1240','Trooper','Rock','Inky','Red Mohawk','White Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2397','1362','Trooper','Rock','Inky','Red Mohawk','White Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2398','1610','Trooper','Rock','Charcoal','Red Mohawk','White Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2399','3459','Trooper','Rock','Plum','Red Mohawk','White Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2400','1708','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2401','1393','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2402','1745','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2403','1202','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2404','1325','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2405','2613','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2406','2658','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2407','1441','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Green Classic Colt','Pink Diamond Axe'],
['#2408','2582','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2409','650','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2410','2276','Trooper','Rock','Inky','Red Mohawk','White Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2411','2321','Trooper','Rock','Charcoal','Red Mohawk','White Chain','Purple Classic Colt','Green Diamond Axe'],
['#2412','1768','Trooper','Rock','Plum','Red Mohawk','White Chain','Purple Classic Colt','Green Diamond Axe'],
['#2413','488','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2414','1987','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2415','2043','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2416','1867','Trooper','Rock','Inky','Green Mohawk','Red Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2417','3199','Trooper','Rock','Inky','Green Mohawk','Red Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2418','1837','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2419','2324','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2420','833','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2421','1237','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2422','2097','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2423','452','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2424','1832','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2425','1496','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2426','1545','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2427','1054','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2428','2002','Trooper','Rock','Inky','Green Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2429','819','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2430','1316','Trooper','Rock','Plum','Green Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2431','2967','Trooper','Rock','Plum','Green Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2432','33','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#2433','1703','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#2434','1715','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#2435','1008','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2436','2201','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2437','2366','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Purple Classic Colt','Green Diamond Axe'],
['#2438','358','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2439','2021','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2440','186','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2441','413','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2442','2471','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2443','284','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2444','689','Trooper','Rock','Inky','Red Mohawk','White Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2445','2107','Trooper','Rock','Plum','Red Mohawk','White Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2446','2792','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2447','2407','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2448','1872','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Turquoise Classic Colt','Pink Diamond Axe'],
['#2449','2824','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2450','1906','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2451','3336','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2452','994','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2453','2550','Trooper','Rock','Inky','Green Mohawk','Red Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2454','1002','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#2455','2392','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2456','1206','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2457','3187','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2458','1131','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#2459','3342','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Green Classic Colt','Green Diamond Axe'],
['#2460','1416','Trooper','Rock','Inky','Purple Mohawk','Red Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2461','239','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#2462','2086','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#2463','1519','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2464','3245','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2465','831','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2466','2293','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2467','557','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Pink Classic Colt','Green Diamond Axe'],
['#2468','2444','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2469','2937','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2470','2720','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2471','2733','Trooper','Rock','Inky','Green Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2472','1266','Trooper','Rock','Plum','Green Mohawk','Red Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2473','1371','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2474','1905','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2475','2921','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2476','2084','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2477','1514','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2478','436','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2479','1660','Trooper','Rock','Inky','Green Mohawk','Red Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2480','3210','Trooper','Rock','Inky','Green Mohawk','Red Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2481','619','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2482','3035','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#2483','2146','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#2484','2228','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#2485','216','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2486','1108','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2487','2595','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2488','2154','Trooper','Rock','Inky','Red Mohawk','Purple Chain','Green Classic Colt','Purple Diamond Axe'],
['#2489','1695','Trooper','Rock','Charcoal','Red Mohawk','Purple Chain','Green Classic Colt','Purple Diamond Axe'],
['#2490','2223','Trooper','Rock','Plum','Red Mohawk','Purple Chain','Green Classic Colt','Purple Diamond Axe'],
['#2491','3170','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Pink Classic Colt','Green Diamond Axe'],
['#2492','1870','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2493','404','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2494','235','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2495','2374','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2496','1921','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2497','1881','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2498','599','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2499','1455','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2500','2551','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2501','2641','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2502','1568','Trooper','Retro','Lime Gradient','White Headphones','Purple Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2503','2127','Trooper','Retro','Lavender Gradient','White Headphones','Purple Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2504','1040','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2505','1532','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2506','1595','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2507','2873','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2508','468','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#2509','852','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Turquoise Classic Colt','Purple Diamond Axe'],
['#2510','538','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2511','3287','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2512','1051','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2513','612','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#2514','859','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#2515','1149','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2516','1335','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2517','2087','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2518','717','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2519','317','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2520','622','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2521','1981','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2522','696','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2523','2684','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2524','3454','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2525','1860','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2526','396','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2527','1575','Trooper','Rock','Inky','Purple Mohawk','Red Chain','Purple Classic Colt','Green Diamond Axe'],
['#2528','2735','Trooper','Rock','Inky','Purple Mohawk','Red Chain','Purple Classic Colt','Green Diamond Axe'],
['#2529','2165','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Purple Classic Colt','Green Diamond Axe'],
['#2530','107','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2531','1935','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2532','1675','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2533','2185','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2534','1453','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2535','1612','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2536','980','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2537','3213','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2538','1963','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2539','2286','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2540','2386','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2541','2079','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2542','3420','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2543','2787','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2544','2903','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2545','2858','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2546','1402','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2547','1950','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2548','478','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2549','1458','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2550','2253','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2551','2401','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2552','2643','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2553','3212','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2554','1554','Trooper','Rock','Inky','Red Mohawk','Red Chain','Green Classic Colt','Green Diamond Axe'],
['#2555','2947','Trooper','Rock','Inky','Red Mohawk','Red Chain','Green Classic Colt','Green Diamond Axe'],
['#2556','1820','Trooper','Rock','Plum','Red Mohawk','Red Chain','Green Classic Colt','Green Diamond Axe'],
['#2557','1592','Trooper','Rock','Plum','Green Mohawk','Purple Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2558','1272','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#2559','1459','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#2560','628','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2561','1470','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2562','895','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2563','2282','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2564','217','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2565','2242','Trooper','Rock','Charcoal','Red Mohawk','White Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2566','1450','Trooper','Rock','Plum','Red Mohawk','White Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2567','838','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2568','2510','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2569','1727','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Green Diamond Axe'],
['#2570','3264','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Green Diamond Axe'],
['#2571','1907','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#2572','102','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2573','114','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2574','2025','Trooper','Hippie','Blush','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2575','2434','Trooper','Rock','Inky','Red Mohawk','White Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2576','737','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2577','3421','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2578','430','Trooper','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2579','1812','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2580','2724','Trooper','Retro','Mango Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#2581','1988','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2582','877','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2583','1122','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2584','2489','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2585','699','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2586','3046','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2587','3466','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2588','589','Trooper','Retro','Lavender Gradient','Blue Headphones','Purple Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2589','303','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Turquoise Classic Colt','Blue Diamond Axe'],
['#2590','1238','Trooper','Rock','Plum','Red Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2591','748','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2592','1796','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2593','804','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2594','1132','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2595','891','Trooper','Retro','Lavender Gradient','White Headphones','Orange Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2596','2316','Trooper','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2597','2941','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2598','1117','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2599','1310','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2600','858','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2601','1215','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Turquoise Classic Colt','Green Diamond Axe'],
['#2602','52','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2603','455','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Yellow Classic Colt','Pink Diamond Axe'],
['#2604','1337','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Pink Diamond Axe'],
['#2605','1247','Trooper','Rock','Inky','Green Mohawk','Red Chain','Pink Classic Colt','Green Diamond Axe'],
['#2606','2368','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2607','81','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2608','2073','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2609','2420','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2610','2153','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#2611','1997','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#2612','182','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2613','3424','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Blue Diamond Axe'],
['#2614','3341','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Yellow Classic Colt','Blue Diamond Axe'],
['#2615','844','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2616','2884','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2617','3160','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2618','3297','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Pink Classic Colt','Purple Diamond Axe'],
['#2619','3282','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2620','44','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Brown Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2621','3012','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2622','1460','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2623','1615','Trooper','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2624','1888','Trooper','Rock','Charcoal','Green Mohawk','Red Chain','Pink Classic Colt','Purple Diamond Axe'],
['#2625','1146','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2626','2278','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2627','1759','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Green Diamond Axe'],
['#2628','2126','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#2629','2741','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Yellow Classic Colt','Green Diamond Axe'],
['#2630','1116','Trooper','Rock','Inky','Green Mohawk','White Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2631','3074','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Blue Diamond Axe'],
['#2632','2049','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2633','2167','Trooper','Retro','Mango Gradient','Green Headphones','White Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2634','1022','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Pink Classic Colt','Green Diamond Axe'],
['#2635','2615','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2636','1093','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2637','1323','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2638','1428','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2639','3205','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2640','1564','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2641','3115','Trooper','Steampunk','Lavender Grey','Brown Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2642','2690','Trooper','Steampunk','Beach Glass','Brown Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#2643','1859','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2644','1735','Trooper','Retro','Lavender Gradient','White Headphones','White Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2645','983','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2646','1404','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2647','595','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Turquoise Diamond Axe'],
['#2648','98','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2649','2376','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Green Classic Colt','Blue Diamond Axe'],
['#2650','569','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2651','832','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2652','31','Trooper','Rock','Plum','Green Mohawk','White Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2653','3453','Trooper','Rock','Plum','Green Mohawk','White Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2654','1432','Trooper','Rock','Inky','Green Mohawk','White Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2655','3152','Trooper','Rock','Inky','Green Mohawk','White Chain','Purple Classic Colt','Pink Diamond Axe'],
['#2656','3081','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Yellow Classic Colt','Pink Diamond Axe'],
['#2657','698','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Yellow Classic Colt','Purple Diamond Axe'],
['#2658','938','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Purple Classic Colt','Purple Diamond Axe'],
['#2659','463','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2660','291','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2661','240','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2662','1969','Trooper','Rock','Inky','Green Mohawk','White Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2663','2951','Trooper','Rock','Plum','Green Mohawk','White Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2664','138','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2665','2221','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2666','420','Trooper','Rock','Plum','Green Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2667','1861','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Green Classic Colt','Green Diamond Axe'],
['#2668','1879','Trooper','Retro','Mango Gradient','White Headphones','White Walkman','Green Classic Colt','Green Diamond Axe'],
['#2669','2070','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Pink Classic Colt','Green Diamond Axe'],
['#2670','1756','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2671','3003','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#2672','2183','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#2673','3348','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#2674','1853','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2675','410','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2676','3276','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2677','893','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2678','2844','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2679','2972','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Turquoise Classic Colt','Purple Diamond Axe'],
['#2680','80','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2681','113','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#2682','2487','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2683','754','Trooper','Rock','Inky','Green Mohawk','White Chain','Purple Classic Colt','Green Diamond Axe'],
['#2684','1503','Trooper','Rock','Inky','Green Mohawk','White Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2685','614','Trooper','Rock','Plum','Green Mohawk','White Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2686','727','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2687','3347','Trooper','Rock','Charcoal','Purple Mohawk','Red Chain','Pink Classic Colt','Turquoise Diamond Axe'],
['#2688','18','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2689','270','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2690','2442','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2691','2381','Trooper','Rock','Inky','Purple Mohawk','White Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2692','702','Trooper','Rock','Plum','Purple Mohawk','White Chain','Turquoise Classic Colt','Pink Diamond Axe'],
['#2693','3387','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Purple Classic Colt','Green Diamond Axe'],
['#2694','1089','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Purple Classic Colt','Green Diamond Axe'],
['#2695','2925','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Purple Classic Colt','Green Diamond Axe'],
['#2696','1113','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2697','1180','Trooper','Retro','Lime Gradient','White Headphones','White Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2698','958','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Pink Classic Colt','Purple Diamond Axe'],
['#2699','1495','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Pink Classic Colt','Purple Diamond Axe'],
['#2700','1930','Trooper','Rock','Inky','Green Mohawk','White Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2701','461','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2702','1327','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#2703','3333','Trooper','Hippie','Glass Green','Blue Head Garland','Pink Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#2704','2810','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#2705','2433','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2706','2590','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2707','1787','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2708','2587','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#2709','1104','Trooper','Rock','Inky','Green Mohawk','White Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2710','2518','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2711','1803','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2712','3090','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2713','1848','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2714','3288','Trooper','Rock','Charcoal','Green Mohawk','Purple Chain','Green Classic Colt','Green Diamond Axe'],
['#2715','1392','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2716','1029','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2717','1332','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2718','2736','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#2719','3299','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#2720','1044','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2721','2836','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2722','474','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#2723','1845','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#2724','1951','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2725','3315','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#2726','2566','Trooper','Rock','Inky','Purple Mohawk','White Chain','Turquoise Classic Colt','Green Diamond Axe'],
['#2727','951','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Green Classic Colt','Blue Diamond Axe'],
['#2728','1993','Trooper','Rock','Inky','Green Mohawk','Purple Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#2729','1159','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Yellow Classic Colt','Blue Diamond Axe'],
['#2730','323','Trooper','Rock','Plum','Purple Mohawk','White Chain','Purple Classic Colt','Blue Diamond Axe'],
['#2731','1228','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2732','2761','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2733','667','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2734','884','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2735','1966','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2736','1225','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#2737','3101','Trooper','Retro','Lime Gradient','Blue Headphones','White Walkman','Green Classic Colt','Blue Diamond Axe'],
['#2738','3371','Trooper','Retro','Lavender Gradient','Blue Headphones','White Walkman','Green Classic Colt','Blue Diamond Axe'],
['#2739','1457','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2740','1648','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2741','3024','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2742','1910','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2743','3150','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2744','1048','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2745','295','Trooper','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#2746','473','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Green Classic Colt','Pink Diamond Axe'],
['#2747','2648','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2748','1682','Trooper','Rock','Inky','Purple Mohawk','White Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2749','2808','Trooper','Rock','Plum','Purple Mohawk','White Chain','Yellow Classic Colt','Green Diamond Axe'],
['#2750','1326','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2751','3021','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Pink Diamond Axe'],
['#2752','1315','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2753','1028','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2754','2281','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2755','1678','Trooper','Rock','Charcoal','Purple Mohawk','Purple Chain','Green Classic Colt','Blue Diamond Axe'],
['#2756','1586','Trooper','Rock','Plum','Purple Mohawk','Purple Chain','Green Classic Colt','Blue Diamond Axe'],
['#2757','248','Trooper','Rock','Inky','Green Mohawk','Red Chain','Green Classic Colt','Pink Diamond Axe'],
['#2758','1584','Trooper','Rock','Plum','Green Mohawk','Red Chain','Green Classic Colt','Pink Diamond Axe'],
['#2759','278','Trooper','Rock','Inky','Purple Mohawk','White Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2760','1749','Trooper','Rock','Inky','Purple Mohawk','White Chain','Purple Classic Colt','Turquoise Diamond Axe'],
['#2761','632','Trooper','Rock','Plum','Purple Mohawk','White Chain','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2762','2019','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Blue Diamond Axe'],
['#2763','746','Trooper','Retro','Lime Gradient','Blue Headphones','Purple Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2764','630','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2765','1027','Trooper','Retro','Mango Gradient','Blue Headphones','White Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#2766','2375','Trooper','Rock','Inky','Purple Mohawk','White Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2767','16','Trooper','Rock','Plum','Purple Mohawk','White Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2768','1259','Trooper','Rock','Plum','Purple Mohawk','White Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2769','1784','Trooper','Rock','Plum','Purple Mohawk','White Chain','Yellow Classic Colt','Purple Diamond Axe'],
['#2770','2769','Trooper','Rock','Plum','Purple Mohawk','White Chain','Purple Classic Colt','Purple Diamond Axe'],
['#2771','425','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2772','2296','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2773','729','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#2774','761','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2775','2055','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2776','2258','Trooper','Rock','Inky','Red Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#2777','3338','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Green Diamond Axe'],
['#2778','2938','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#2779','1546','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2780','1901','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#2781','161','Trooper','Rock','Inky','Red Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#2782','3133','Trooper','Rock','Plum','Red Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#2783','247','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2784','324','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2785','2307','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2786','418','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2787','1954','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Green Classic Colt','Green Diamond Axe'],
['#2788','2027','Trooper','Retro','Lime Gradient','White Headphones','Orange Walkman','Green Classic Colt','Green Diamond Axe'],
['#2789','2589','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Green Classic Colt','Green Diamond Axe'],
['#2790','2996','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Green Classic Colt','Green Diamond Axe'],
['#2791','604','Trooper','Retro','Mango Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Turquoise Diamond Axe'],
['#2792','1161','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2793','1419','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2794','1198','Trooper','Rock','Inky','Purple Mohawk','Purple Chain','Green Classic Colt','Purple Diamond Axe'],
['#2795','55','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2796','369','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2797','740','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Blue Diamond Axe'],
['#2798','3445','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Blue Diamond Axe'],
['#2799','1059','Trooper','Rock','Inky','Green Mohawk','Red Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#2800','1191','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2801','3064','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2802','1196','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#2803','1520','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2804','1284','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Pink Classic Colt','Purple Diamond Axe'],
['#2805','3344','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2806','2056','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2807','2495','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#2808','2454','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#2809','873','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2810','2355','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2811','207','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2812','1195','Trooper','Retro','Mango Gradient','White Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2813','2547','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Green Diamond Axe'],
['#2814','2364','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2815','1213','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2816','2190','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2817','2918','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2818','1560','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#2819','3176','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#2820','2133','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#2821','2712','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#2822','568','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#2823','2596','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#2824','343','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#2825','434','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2826','1657','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2827','231','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2828','1485','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2829','2857','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#2830','811','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Green Classic Colt','Blue Diamond Axe'],
['#2831','624','Trooper','Rock','Inky','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2832','1350','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2833','1422','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2834','1958','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2835','3254','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2836','3367','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2837','3411','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2838','3414','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2839','2934','Trooper','Retro','Lime Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#2840','3408','Trooper','Retro','Lavender Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#2841','71','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#2842','1563','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2843','1570','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#2844','1619','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2845','1353','Trooper','Retro','Lime Gradient','Green Headphones','White Walkman','Green Classic Colt','Green Diamond Axe'],
['#2846','919','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Green Classic Colt','Green Diamond Axe'],
['#2847','2113','Trooper','Retro','Lavender Gradient','Green Headphones','White Walkman','Green Classic Colt','Green Diamond Axe'],
['#2848','220','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2849','2689','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Pink Classic Colt','Green Diamond Axe'],
['#2850','93','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#2851','2584','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#2852','424','Trooper','Retro','Mango Gradient','Green Headphones','Purple Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2853','2525','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2854','3431','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2855','602','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2856','927','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#2857','2994','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Green Classic Colt','Green Diamond Axe'],
['#2858','1346','Trooper','Retro','Lime Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2859','3436','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2860','253','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#2861','1559','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#2862','1518','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Pink Classic Colt','Purple Diamond Axe'],
['#2863','601','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2864','703','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2865','3266','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2866','655','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2867','1443','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#2868','1507','Trooper','Cyberpunk','Maui Blue','Blue Mask','Blue Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#2869','767','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2870','2784','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#2871','1357','Trooper','Rock','Plum','Purple Mohawk','White Chain','Pink Classic Colt','Pink Diamond Axe'],
['#2872','1777','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2873','2266','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2874','2115','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2875','2477','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2876','2854','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2877','1484','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2878','213','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2879','2425','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2880','2516','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2881','2569','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2882','3331','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2883','3250','Trooper','Rock','Plum','Purple Mohawk','Red Chain','Green Classic Colt','Purple Diamond Axe'],
['#2884','2681','Trooper','Rock','Inky','Purple Mohawk','White Chain','Pink Classic Colt','Blue Diamond Axe'],
['#2885','1271','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#2886','1833','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#2887','513','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#2888','1805','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#2889','87','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2890','1475','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2891','1647','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2892','2304','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2893','3103','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2894','827','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2895','1255','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2896','1903','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2897','1667','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#2898','3472','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#2899','918','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Pink Classic Colt','Green Diamond Axe'],
['#2900','2085','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2901','593','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2902','1049','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2903','3111','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2904','3165','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2905','1465','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2906','2805','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#2907','3476','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2908','35','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2909','914','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#2910','2331','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2911','3310','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2912','3118','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#2913','214','Trooper','Hippie','Lilac','Blue Head Garland','Pink Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#2914','2677','Trooper','Retro','Mango Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2915','27','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2916','202','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2917','2992','Trooper','Retro','Lavender Gradient','Blue Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2918','684','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2919','1262','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2920','1814','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#2921','2129','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#2922','866','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#2923','2509','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#2924','1874','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2925','1090','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2926','636','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2927','480','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2928','1291','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2929','2041','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2930','2051','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2931','1724','Trooper','Rock','Plum','Purple Mohawk','White Chain','Pink Classic Colt','Purple Diamond Axe'],
['#2932','1593','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#2933','661','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2934','888','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2935','1341','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2936','292','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#2937','1055','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2938','590','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2939','2460','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2940','83','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2941','2275','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#2942','1414','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#2943','577','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#2944','747','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#2945','3223','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2946','2638','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2947','2088','Trooper','Retro','Lime Gradient','Green Headphones','Orange Walkman','Green Classic Colt','Pink Diamond Axe'],
['#2948','2455','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2949','1565','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#2950','749','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#2951','1433','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2952','3340','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2953','459','Trooper','Hippie','Blush','Blue Head Garland','Blue Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#2954','428','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2955','1672','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2956','204','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#2957','1713','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2958','2461','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#2959','2782','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Yellow Classic Colt','Green Diamond Axe'],
['#2960','522','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#2961','3325','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#2962','445','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2963','1790','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2964','1477','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2965','3364','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#2966','1349','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2967','1801','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#2968','1705','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#2969','175','Trooper','Hippie','Lilac','Blue Head Garland','Blue Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#2970','1317','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#2971','3473','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#2972','616','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#2973','2024','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#2974','3378','Trooper','Hippie','Glass Green','Pink Head Garland','Blue Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#2975','340','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#2976','2459','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2977','2726','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2978','388','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#2979','1582','Trooper','Hippie','Glass Green','Blue Head Garland','Blue Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#2980','2705','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#2981','3465','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#2982','3106','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#2983','1700','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#2984','1821','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Green Classic Colt','Turquoise Diamond Axe'],
['#2985','2633','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2986','1278','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#2987','2415','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#2988','2334','Trooper','Rock','Plum','Green Mohawk','White Chain','Green Classic Colt','Pink Diamond Axe'],
['#2989','917','Trooper','Retro','Lavender Gradient','Green Headphones','Orange Walkman','Green Classic Colt','Purple Diamond Axe'],
['#2990','1635','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2991','2095','Trooper','Cyberpunk','Monster Green','Green Mask','Blue Earphones','Green Classic Colt','Pink Diamond Axe'],
['#2992','880','Trooper','Rock','Inky','Green Mohawk','White Chain','Green Classic Colt','Blue Diamond Axe'],
['#2993','2283','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Green Classic Colt','Blue Diamond Axe'],
['#2994','2177','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#2995','266','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#2996','2039','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#2997','1381','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Pink Diamond Axe'],
['#2998','1875','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#2999','2261','Trooper','Cyberpunk','Marigold','Green Mask','Blue Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3000','2943','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3001','704','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3002','1955','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#3003','3174','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#3004','3230','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#3005','1354','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#3006','520','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#3007','573','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#3008','1352','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3009','2564','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#3010','3375','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3011','3389','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3012','3434','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3013','165','Trooper','Rock','Charcoal','Green Mohawk','White Chain','Green Classic Colt','Green Diamond Axe'],
['#3014','314','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3015','2104','Trooper','Steampunk','Lavender Grey','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3016','1978','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3017','1815','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3018','498','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#3019','3470','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3020','2908','Trooper','Cyberpunk','Marigold','Blue Mask','Blue Earphones','Green Classic Colt','Green Diamond Axe'],
['#3021','2798','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#3022','2322','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3023','1728','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3024','392','Trooper','Rock','Plum','Green Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#3025','1497','Trooper','Rock','Plum','Green Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#3026','1509','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3027','1608','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3028','2424','Trooper','Cyberpunk','Maui Blue','Green Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3029','372','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3030','499','Trooper','Cyberpunk','Monster Green','Blue Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3031','528','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3032','1998','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3033','147','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3034','2685','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3035','1064','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3036','3407','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#3037','1583','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3038','444','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#3039','299','Trooper','Hippie','Lilac','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#3040','2410','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3041','2708','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3042','104','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3043','941','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3044','1208','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3045','289','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#3046','584','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#3047','124','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#3048','1169','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Yellow Classic Colt','Purple Diamond Axe'],
['#3049','1351','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#3050','419','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3051','3159','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3052','2267','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3053','782','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3054','2136','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Green Classic Colt','Blue Diamond Axe'],
['#3055','2363','Trooper','Rock','Plum','Purple Mohawk','White Chain','Green Classic Colt','Blue Diamond Axe'],
['#3056','72','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3057','1124','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3058','3203','Trooper','Hippie','Blush','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3059','921','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#3060','3337','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#3061','1883','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#3062','122','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3063','2486','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3064','3486','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3065','2727','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#3066','26','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3067','3042','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3068','2484','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#3069','1322','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3070','1487','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3071','2883','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Green Classic Colt','Green Diamond Axe'],
['#3072','759','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#3073','2467','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#3074','3167','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Turquoise Classic Colt','Green Diamond Axe'],
['#3075','3116','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3076','3294','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3077','1630','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3078','1702','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#3079','633','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3080','2983','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3081','189','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3082','1722','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3083','887','Trooper','Hippie','Glass Green','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3084','1530','Trooper','Hippie','Lilac','Pink Head Garland','Pink Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3085','854','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3086','2830','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3087','3083','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3088','3309','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3089','1324','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3090','319','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3091','1001','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3092','2520','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3093','2837','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3094','3238','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3095','711','Trooper','Rock','Inky','Purple Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#3096','3055','Trooper','Rock','Inky','Purple Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#3097','1088','Trooper','Rock','Plum','Purple Mohawk','White Chain','Green Classic Colt','Turquoise Diamond Axe'],
['#3098','375','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3099','82','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3100','233','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#3101','254','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#3102','1566','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#3103','2054','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#3104','1931','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3105','2818','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3106','2905','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3107','3169','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3108','333','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3109','1842','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3110','1136','Trooper','Rock','Charcoal','Purple Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#3111','2813','Trooper','Rock','Plum','Purple Mohawk','White Chain','Green Classic Colt','Purple Diamond Axe'],
['#3112','288','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3113','15','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3114','49','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3115','482','Trooper','Cyberpunk','Maui Blue','Blue Mask','Pink Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3116','1624','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3117','544','Trooper','Hippie','Blush','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3118','1864','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3119','495','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#3120','3197','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3121','472','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3122','2378','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3123','2791','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3124','3363','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#3125','566','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#3126','440','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#3127','2120','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3128','942','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3129','1808','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3130','1831','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3131','1934','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3132','2871','Trooper','Steampunk','Beach Glass','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3133','1839','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3134','219','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3135','1977','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3136','1825','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#3137','2238','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#3138','3142','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Pink Diamond Axe'],
['#3139','1830','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3140','2234','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#3141','2931','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#3142','2740','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#3143','1145','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#3144','2563','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Blue Diamond Axe'],
['#3145','466','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3146','2456','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3147','2669','Trooper','Hippie','Lilac','Pink Head Garland','Blue Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#3148','2894','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Pink Diamond Axe'],
['#3149','2717','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3150','374','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#3151','3131','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Green Diamond Axe'],
['#3152','2513','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3153','2683','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3154','995','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Pink Diamond Axe'],
['#3155','3069','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3156','1444','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3157','2080','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3158','657','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3159','2771','Trooper','Steampunk','Lavender Grey','Brown Goggles','Yellow Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3160','2703','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3161','405','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3162','3351','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#3163','1083','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Yellow Classic Colt','Blue Diamond Axe'],
['#3164','3442','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Blue Diamond Axe'],
['#3165','2501','Trooper','Steampunk','Burnt Sienna','Brown Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3166','787','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#3167','335','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Blue Diamond Axe'],
['#3168','491','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#3169','2816','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Turquoise Classic Colt','Purple Diamond Axe'],
['#3170','588','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3171','882','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Green Diamond Axe'],
['#3172','297','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3173','302','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3174','762','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3175','648','Trooper','Hippie','Glass Green','Yellow Head Garland','Pink Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3176','982','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3177','1331','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#3178','66','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#3179','2451','Trooper','Hippie','Lilac','Blue Head Garland','Green Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#3180','3314','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#3181','772','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#3182','2606','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Turquoise Diamond Axe'],
['#3183','706','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3184','3300','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3185','1747','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3186','426','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3187','48','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3188','2225','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3189','2814','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3190','1492','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3191','2609','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3192','1483','Trooper','Hippie','Blush','Blue Head Garland','Green Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3193','2750','Trooper','Hippie','Glass Green','Blue Head Garland','Green Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3194','1606','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Purple Classic Colt','Purple Diamond Axe'],
['#3195','1445','Trooper','Steampunk','Beach Glass','Brown Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3196','3423','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3197','1504','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3198','985','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3199','3029','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3200','348','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#3201','2263','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3202','3125','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3203','3047','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3204','510','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3205','2244','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3206','1789','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#3207','2360','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#3208','3256','Trooper','Hippie','Blush','Yellow Head Garland','Pink Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3209','1066','Trooper','Cyberpunk','Maui Blue','Green Mask','Pink Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3210','807','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3211','3271','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#3212','2469','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Purple Classic Colt','Purple Diamond Axe'],
['#3213','281','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#3214','862','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3215','1086','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3216','2152','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3217','901','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3218','2503','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3219','2292','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3220','131','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3221','1293','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3222','949','Trooper','Steampunk','Beach Glass','Black Goggles','Brown Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3223','1641','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Green Classic Colt','Green Diamond Axe'],
['#3224','1834','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Green Classic Colt','Green Diamond Axe'],
['#3225','934','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Green Classic Colt','Green Diamond Axe'],
['#3226','1676','Trooper','Cyberpunk','Monster Green','Green Mask','Pink Earphones','Green Classic Colt','Green Diamond Axe'],
['#3227','1916','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3228','718','Trooper','Cyberpunk','Marigold','Green Mask','Pink Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3229','2003','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3230','13','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3231','1205','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3232','2710','Trooper','Hippie','Glass Green','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3233','1915','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3234','2949','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3235','1019','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3236','3354','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3237','2384','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3238','3182','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Green Classic Colt','Pink Diamond Axe'],
['#3239','2644','Trooper','Cyberpunk','Marigold','Blue Mask','Pink Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3240','1549','Trooper','Cyberpunk','Monster Green','Blue Mask','Pink Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3241','277','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3242','259','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#3243','143','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#3244','451','Trooper','Hippie','Lilac','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#3245','2209','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3246','2211','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3247','3007','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3248','1751','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3249','1984','Trooper','Hippie','Blush','Yellow Head Garland','Blue Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3250','1017','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Green Classic Colt','Green Diamond Axe'],
['#3251','79','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Green Classic Colt','Green Diamond Axe'],
['#3252','1071','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3253','2519','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Blue Diamond Axe'],
['#3254','185','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3255','701','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3256','1004','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3257','871','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3258','1073','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#3259','167','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3260','1863','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3261','1927','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3262','1817','Trooper','Cyberpunk','Marigold','Yellow Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3263','431','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3264','2656','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3265','1005','Trooper','Hippie','Blush','Pink Head Garland','Green Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3266','533','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#3267','1684','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Green Diamond Axe'],
['#3268','3253','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3269','3388','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Blue Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3270','512','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3271','2872','Trooper','Cyberpunk','Monster Green','Yellow Mask','Blue Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3272','3339','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3273','2956','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3274','2417','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3275','2338','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3276','1380','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3277','3346','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3278','1114','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3279','3353','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Turquoise Classic Colt','Pink Diamond Axe'],
['#3280','1045','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3281','305','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#3282','2600','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Turquoise Classic Colt','Pink Diamond Axe'],
['#3283','1480','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#3284','3251','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Turquoise Diamond Axe'],
['#3285','518','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3286','964','Trooper','Steampunk','Lavender Grey','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3287','1105','Trooper','Steampunk','Beach Glass','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3288','540','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3289','1068','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3290','3137','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#3291','1602','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#3292','1810','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Pink Classic Colt','Blue Diamond Axe'],
['#3293','1273','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3294','1792','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3295','1003','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3296','1491','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3297','2923','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3298','51','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3299','1683','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Pink Classic Colt','Purple Diamond Axe'],
['#3300','2188','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3301','2037','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3302','2273','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3303','2432','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3304','2341','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3305','1873','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3306','1919','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3307','1631','Trooper','Hippie','Glass Green','Pink Head Garland','Green Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#3308','605','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Yellow Classic Colt','Pink Diamond Axe'],
['#3309','744','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3310','1285','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Pink Diamond Axe'],
['#3311','600','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3312','944','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3313','3077','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Yellow Classic Colt','Pink Diamond Axe'],
['#3314','3061','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3315','2767','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Yellow Classic Colt','Blue Diamond Axe'],
['#3316','653','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3317','1356','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Blue Diamond Axe'],
['#3318','688','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3319','523','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3320','1797','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Blue Diamond Axe'],
['#3321','2621','Trooper','Hippie','Lilac','Pink Head Garland','Green Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3322','3298','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3323','563','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3324','2090','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Brown Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3325','2531','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3326','439','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3327','3039','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#3328','2573','Trooper','Cyberpunk','Monster Green','Yellow Mask','Pink Earphones','Pink Classic Colt','Turquoise Diamond Axe'],
['#3329','3166','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Turquoise Classic Colt','Turquoise Diamond Axe'],
['#3330','2528','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3331','803','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Turquoise Classic Colt','Purple Diamond Axe'],
['#3332','1634','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#3333','1454','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3334','2046','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#3335','708','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Yellow Classic Colt','Green Diamond Axe'],
['#3336','961','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3337','1231','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Green Diamond Axe'],
['#3338','742','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Turquoise Classic Colt','Blue Diamond Axe'],
['#3339','2062','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3340','2538','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3341','1314','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3342','1737','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3343','774','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3344','2089','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3345','38','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#3346','867','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#3347','2326','Trooper','Cyberpunk','Marigold','Blue Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#3348','2023','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3349','808','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3350','1670','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3351','211','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Purple Classic Colt','Turquoise Diamond Axe'],
['#3352','1662','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3353','2898','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3354','1729','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3355','606','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3356','2395','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3357','2494','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3358','869','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3359','968','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3360','2981','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Turquoise Classic Colt','Green Diamond Axe'],
['#3361','293','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3362','822','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3363','2217','Trooper','Cyberpunk','Monster Green','Blue Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3364','2318','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3365','1996','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Purple Classic Colt','Pink Diamond Axe'],
['#3366','2917','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Yellow Classic Colt','Purple Diamond Axe'],
['#3367','2592','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3368','1056','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3369','2053','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3370','2476','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3371','1637','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3372','432','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3373','923','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3374','2612','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3375','3323','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3376','1846','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Purple Classic Colt','Purple Diamond Axe'],
['#3377','205','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3378','609','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3379','3350','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Purple Classic Colt','Blue Diamond Axe'],
['#3380','1174','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3381','1020','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3382','3296','Trooper','Steampunk','Burnt Sienna','Black Goggles','Brown Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3383','795','Trooper','Steampunk','Lavender Grey','Black Goggles','Brown Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3384','3053','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#3385','660','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Turquoise Classic Colt','Purple Diamond Axe'],
['#3386','3487','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3387','300','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3388','1898','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3389','2966','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Purple Classic Colt','Green Diamond Axe'],
['#3390','2549','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Yellow Classic Colt','Green Diamond Axe'],
['#3391','565','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3392','2778','Trooper','Cyberpunk','Maui Blue','Green Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3393','786','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3394','3285','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3395','682','Trooper','Cyberpunk','Monster Green','Green Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3396','1397','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Yellow Classic Colt','Turquoise Diamond Axe'],
['#3397','1838','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Purple Classic Colt','Turquoise Diamond Axe'],
['#3398','3471','Trooper','Cyberpunk','Maui Blue','Blue Mask','Green Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3399','2302','Trooper','Cyberpunk','Marigold','Green Mask','Green Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3400','3153','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3401','1226','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Yellow Classic Colt','Purple Diamond Axe'],
['#3402','3141','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#3403','933','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3404','2765','Trooper','Hippie','Lilac','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Pink Diamond Axe'],
['#3405','1673','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Blue Diamond Axe'],
['#3406','3477','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3407','1698','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3408','2074','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Green Diamond Axe'],
['#3409','975','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3410','851','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3411','402','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3412','3154','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3413','2985','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Pink Classic Colt','Pink Diamond Axe'],
['#3414','558','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3415','644','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3416','956','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3417','2775','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3418','2904','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3419','3189','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3420','1050','Trooper','Hippie','Blush','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Turquoise Diamond Axe'],
['#3421','2861','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#3422','2668','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#3423','3184','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#3424','1192','Trooper','Hippie','Glass Green','Yellow Head Garland','Green Sling Bag','Green Classic Colt','Purple Diamond Axe'],
['#3425','1785','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Pink Classic Colt','Green Diamond Axe'],
['#3426','275','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3427','173','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3428','2906','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3429','671','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3430','2541','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3431','3279','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3432','105','Trooper','Cyberpunk','Marigold','Yellow Mask','Pink Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3433','2478','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3434','1781','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3435','2907','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Pink Classic Colt','Pink Diamond Axe'],
['#3436','3109','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Pink Classic Colt','Purple Diamond Axe'],
['#3437','2768','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3438','603','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3439','2944','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3440','850','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3441','2504','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3442','427','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Pink Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3443','218','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Pink Classic Colt','Blue Diamond Axe'],
['#3444','3478','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3445','572','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3446','2706','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Pink Classic Colt','Green Diamond Axe'],
['#3447','1087','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3448','2548','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Pink Classic Colt','Turquoise Diamond Axe'],
['#3449','3079','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Pink Classic Colt','Purple Diamond Axe'],
['#3450','574','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3451','2896','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3452','3391','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3453','1118','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3454','1401','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3455','3343','Trooper','Steampunk','Beach Glass','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3456','2557','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Green Classic Colt','Pink Diamond Axe'],
['#3457','2169','Trooper','Steampunk','Lavender Grey','Grey Goggles','Yellow Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3458','525','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3459','560','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3460','3304','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Green Classic Colt','Blue Diamond Axe'],
['#3461','397','Trooper','Steampunk','Lavender Grey','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3462','2284','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3463','1753','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3464','3048','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3465','658','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3466','1039','Trooper','Steampunk','Beach Glass','Grey Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3467','476','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3468','1148','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3469','170','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3470','1209','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3471','2650','Trooper','Cyberpunk','Monster Green','Yellow Mask','Green Earphones','Green Classic Colt','Green Diamond Axe'],
['#3472','2385','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3473','1224','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3474','1573','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3475','1818','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3476','2790','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Green Classic Colt','Green Diamond Axe'],
['#3477','151','Trooper','Cyberpunk','Marigold','Yellow Mask','Green Earphones','Green Classic Colt','Turquoise Diamond Axe'],
['#3478','1139','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3479','1819','Trooper','Steampunk','Beach Glass','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3480','234','Trooper','Steampunk','Burnt Sienna','Black Goggles','Black Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3481','134','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3482','2497','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Green Classic Colt','Pink Diamond Axe'],
['#3483','2197','Trooper','Cyberpunk','Maui Blue','Yellow Mask','Green Earphones','Green Classic Colt','Purple Diamond Axe'],
['#3484','726','Trooper','Steampunk','Burnt Sienna','Black Goggles','Yellow Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3485','623','Trooper','Steampunk','Burnt Sienna','Grey Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3486','2751','Trooper','Steampunk','Lavender Grey','Grey Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe'],
['#3487','2328','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Green Classic Colt','Blue Diamond Axe'],
['#3488','828','Trooper','Steampunk','Lavender Grey','Black Goggles','Black Pocket Watch','Green Classic Colt','Turquoise Diamond Axe'],
['#3489','1835','Trooper','Steampunk','Beach Glass','Black Goggles','Black Pocket Watch','Green Classic Colt','Purple Diamond Axe']];

export default Mapping;