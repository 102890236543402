import React, { useRef } from "react";
import classnames from "classnames";
import { useInViewport } from "ahooks";

import roadmaps from "./roadmaps";
import styles from "./index.module.scss";

const RoadMapItem = ({
  image,
  time,
  title,
  description,
  subDescription,
  className,
  roadMapKey,
  url,
}) => {
  let titleComponent = <div className={styles.title}>{title}</div>;
  return (
    <div
      className={classnames(className, styles["roadmap-item"])}
      onClick={() => {
        if (url) {
          window.open(url);
        }
      }}
      style={{
        cursor: url ? "pointer" : "",
      }}
    >
      <div>
        <img className={styles.image} src={image} />
      </div>
      <div>
        <div className={styles.time}>{time}</div>
        {titleComponent}
        <div className={styles.description}>{description}</div>
        {subDescription && (
          <div
            className={styles.subDescription}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {subDescription}
          </div>
        )}
      </div>
    </div>
  );
};

const RoadMap = () => {
  const ref = useRef();
  const inViewPort = useInViewport(ref);
  return (
    <div className={styles.wrapper}>
      <div id="roadmap" ref={ref} className={styles["roadmap-container"]}>
        <div className={styles["roadmap-title"]}>ROADMAP</div>
        <div>
          {inViewPort
            ? roadmaps.map((roadmap, key) => {
                return <RoadMapItem {...roadmap} roadMapKey={key} />;
              })
            : null}
        </div>
      </div>
      {/* <div className={styles["roadmap-promt"]}>
            The MadTrooper IP will continue having various, real-world
            applications in entertainment, retail, media and gaming within the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://eddaverse.com/#roadmap"
              className={styles["roadmap-promt-link"]}
            >
              EDDAVerse ecosystem
            </a>
          </div> */}
    </div>
  );
};

export default RoadMap;
